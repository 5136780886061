import React, { Component } from 'react';
import Navigacija from './Navigacija';
import Footer from './Footer';
import {Helmet} from "react-helmet";

//Komponenta za kontakt
class Studio extends Component {

  //Kad se učita srolla se na vrh
  componentDidMount() {
     window.scrollTo(0, 0);
  }

  //Definiranje state-a
  constructor(props) {
      super(props);
      this.state = {
        slike: ["1.jpg", "2.jpg", "3.jpg", "4.jpg", "5.jpg", "6.jpg", 
        "7.jpg", "8.jpg", "9.jpg", "10.jpg", "11.jpg", "12.jpg", "13.jpg", 
        "14.jpg", "15.jpg", "16.jpg"]
      };

      this.onClick = this.onClick.bind(this);
      this.closeModal = this.closeModal.bind(this);
      
  }


  onClick(event) {
    const urlSlika=event.target.id;
    console.log(urlSlika);
    document.getElementById("img01").src = "images/studio/"+urlSlika;
    document.getElementById("modal01").style.display = "block";
}

 closeModal () {
  const modular = document.getElementById('modal01');
  modular.style.display = 'none';
  
 }


  //Ispis galerije po slikama
  ispisGalerije(){
     if(this.state.slike) {
     return(
         this.state.slike.map(slika => (
            <div className="w3-col l3 m6" key={slika}>
              <div id={slika} 
                onClick={this.onClick} 
                className="rukica galerija" 
                style={{backgroundImage: 'url(images/studio/' + slika+')'}} >
              </div>
            </div>
         ))
      );
     }
     else{
        return (
          <div>
            
            <p>Članak nema galeriju slika.</p>
          </div>
        );
     }
  }

  //Renderiranje sadržaja
  render() {
    return (
      <div>

         <Helmet>
             <title>RADIO ROŽA RIJEKA - STUDIO</title>
         </Helmet>

        <Navigacija />


         {/* HEADER */}
         <header className=" header-ne-naslovnica  w3-display-container w3-center" id="home">

                <div className="header w3-text-white ">
                    <h1 className="w3-xxxlarge w3-wide w3-animate-opacity " >STUDIO</h1>
                    <p className="w3-wide podnaslov-header">RADIO ROŽA - RADIO ZAJEDNICE</p><hr/>
                </div>
              
         </header>

         {/* O STUDIU */}
         <div className="w3-container main-padding naslovnica-ploca"  id="about">
          
              <p> Radio Roža svoj dom dobiva u Omladinskom kulturnom centru Palach, te od 
              siječnja 2019. godine započinje s uređenjem i opremanjem prostora koji će 
              služiti kao radio zajednice, promovirajući razne bendove, izvođače, selektore
              i druge umjetnike koji djeluju ili gostuju u Rijeci. </p> <br/>
        

          <div className="w3-center">
             
                {/* VIDEO */}
               
              
                   <video className="video-studio" poster="images/placeholder.png" controls >
                     <source src="images/rr_studio.mp4" type="video/mp4" />              
                     Vaš preglednik ne podržava format videa.
                   </video>
                   
                   <p>Video: Mara Prpić</p><br/>
              
                {/* PHOTO GALERIJA */}
                <h2 className="w3-wide">FOTO GALERIJA </h2><hr/><br/>

                    <div class="w3-row-padding w3-center">

                      {this.ispisGalerije()}                   
                      <p>Fotografije: Mara Prpić</p><hr/>

                         <div id="modal01" className="w3-modal w3-black" onClick={this.closeModal}>
                            <span className="w3-button w3-xxlarge w3-black w3-padding-large w3-display-topright" title="Close Modal Image">×</span>
                           <div className="w3-modal-content w3-animate-zoom w3-center w3-transparent w3-padding-64">
                            <img id="img01" className="w3-image" alt="Radio Roža" />
                            <p id="caption" className="w3-opacity w3-large"></p>
                           </div>
                         </div>

                    </div>                
              
           </div> 
        </div>  {/* END CONTAINER */}    
           
        
          <Footer />
      </div>
    );
  }
}

export default Studio;
