//import react modula
import "react-app-polyfill/ie9";
import React, { Component } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import * as serviceWorker from "./serviceWorker";

//import custom modula
import Home from "./komponente/Home";
import Onama from "./komponente/Onama";
import Novosti from "./komponente/Novosti";
import Novost from "./komponente/Novost";
import Galerija from "./komponente/Galerija";
import Ziroskop from "./komponente/Ziroskop";
import KoronaKid from "./komponente/KoronaKid";
import Bitni17 from "./komponente/Bitni17";
import Odasiljac from "./komponente/Odasiljac";
import DigitalneRozice from "./komponente/DigitalneRozice";
import Program from "./komponente/Program";
import Serijali from "./komponente/Serijali";
import Serijal from "./komponente/Serijal";
import Kontakt from "./komponente/Kontakt";
import Podcast from "./komponente/Podcast";
import Impresum from "./komponente/Impresum";
import Privatnost from "./komponente/Privatnost";
import Kako from "./komponente/Kako";
import Studio from "./komponente/Studio";
import Crew from "./komponente/Crew";
import DashPlayer from "./komponente/DashPlayer";
import Page404 from "./komponente/Page404";

import Plejer from "./komponente/Plejer";
import InkluzivniKotac from "./komponente/InkluzivniKotac";

//Komponenta sa react routerom
class Router extends Component {
  render() {
    return (
      <div>
        <BrowserRouter>
          <Switch>
            <Route path="/" exact component={Home} />
            <Route path="/o-nama" exact component={Onama} />
            <Route path="/novosti" exact component={Novosti} />
            <Route path="/novost-:friendly_url" exact component={Novost} />
            <Route
              path="/galerija-:idNovosti-:idSlike"
              exact
              component={Galerija}
            />
            <Route path="/ziroskop" exact component={Ziroskop} />
            <Route path="/korona-kid" exact component={KoronaKid} />
            <Route path="/bitni17" exact component={Bitni17} />
            <Route path="/odasiljac" exact component={Odasiljac} />
            <Route path="/digitalne-rozice" exact component={DigitalneRozice} />
            <Route path="/inkluzivni-kotac" exact component={InkluzivniKotac} />
            <Route path="/program" exact component={Program} />
            <Route path="/serijali" exact component={Serijali} />
            <Route path="/serijal-:friendly_url" exact component={Serijal} />
            <Route path="/kontakt" exact component={Kontakt} />
            <Route path="/podcast" exact component={Podcast} />
            <Route path="/impressum" exact component={Impresum} />
            <Route path="/privatnost" exact component={Privatnost} />
            <Route path="/kako" exact component={Kako} />
            <Route path="/crew" exact component={Crew} />
            <Route path="/studio" exact component={Studio} />
            <Route path="/dash" exact component={DashPlayer} />
            <Route component={Page404} />
          </Switch>
        </BrowserRouter>
      </div>
    );
  }
}

//Renderira router u div sa idem root
ReactDOM.render(
  <div>
    <Plejer />
    <Router />
  </div>,
  document.getElementById("root")
);

serviceWorker.unregister();
