import React, { Component } from "react";
import Navigacija from "./Navigacija";
import Footer from "./Footer";
import { Helmet } from "react-helmet";
import dashjs from "dashjs";

class DashPlayer extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
    this.menuActive();
    this.loadDashPlayer();
  }

  menuActive() {
    document.getElementById("kontakt").classList.add("w3-red");
  }

  loadDashPlayer() {
    const player_audio = document.getElementById("dash-player");

    if (window.MediaSource) {
      const player = dashjs.MediaPlayer().create();
      const stream_url_dash = "https://radio-roza.org/stream/dash/live.mpd";
      player.initialize(player_audio, stream_url_dash, true);
    } else {
      const stream_url_hls = "https://radio-roza.org/stream/hls/live.m3u8";
      player_audio.setAttribute("src", stream_url_hls);
    }
  }

  //Renderiranje sadržaja
  render() {
    return (
      <div>
        <Helmet>
          <title>RADIO ROŽA RIJEKA - DASHJS - PLAYER</title>
        </Helmet>

        <Navigacija />

        {/* HEADER */}
        <header
          className=" header-ne-naslovnica  w3-display-container w3-center"
          id="home"
        >
          <div className="header w3-text-white ">
            <h1 className="w3-xxxlarge w3-wide w3-animate-opacity ">
              DASH JS PLAYER
            </h1>
            <p className="w3-wide podnaslov-header">
              RADIO ROŽA - RADIO ZAJEDNICE
            </p>
            <hr />
          </div>
        </header>

        {/* KONTAKT */}
        <div className="w3-container main-padding naslovnica-ploca" id="about">
          <audio id="dash-player" controls="audio"></audio>
        </div>

        <Footer />
      </div>
    );
  }
}

export default DashPlayer;
