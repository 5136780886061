import React, { Component } from 'react';
import Navigacija from './Navigacija';
import Footer from './Footer';
import {Helmet} from "react-helmet";
import ReactHtmlParser from 'react-html-parser';

//Komponenta za prikaz jedne novoti - READ ONE
class Novost extends Component {

  //Definiranje state-a
  constructor(props) {
      super(props);
      this.state = {
        id: '',
        name: '',
        description: '',
        image: '',
        podcast_link: '',
        slike: []
      };

      this.onClick = this.onClick.bind(this);
      this.closeModal = this.closeModal.bind(this);
      this.showButtonStream = this.showButtonStream.bind(this);
  }

  //Kad se učita: scroll na vrh i dohvati novost
  componentDidMount(){
     window.scrollTo(0, 0);
     this.dohvacanjeNovosti();
  }

  //Funkcija sa api pozivom za READ ONE novosti
  dohvacanjeNovosti() {
     fetch("https://www.mar-com.hr/radio-davor/rest/read_one.php?friendly_url=" + this.props.match.params.friendly_url)
     .then(res => res.json())
     .then(
        (result) => {
          this.setState({
           id: result.id,
           name: result.name,
           text: result.text,
           image: result.image,
           podcast_link: result.podcast_link
          });
          this.dohvacanjeGalerije();
        },
        (error) => {
           console.log(error);
        });
  }

  //FUNKCIJA ZA DOHVAĆANJE GALERIJE SLIKA ZA NOVOST
  dohvacanjeGalerije() {

    fetch("https://www.mar-com.hr/radio-davor/rest/read_galerija.php?id=" + this.state.id)
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            slike: result.records
          });
        },
        (error) => {
            console.log(error);
        });
  }


 onClick(event) {
  const urlSlika=event.target.id;
  console.log(urlSlika);
  document.getElementById("img01").src = "https://www.mar-com.hr/radio-davor/admin/products/galerija/uploads/"+urlSlika;
  document.getElementById("modal01").style.display = "block";
 

}

 closeModal () {
  const modular = document.getElementById('modal01');
  modular.style.display = 'none';
  
 }


 showButtonStream () {
 if (this.state.podcast_link) {
    return( <div>
    
     <a rel="noopener noreferrer" target="_blank" href={this.state.podcast_link}>
     <button className="rr-botun-xl fbold w3-animate-right "> 
        <img className="logo-light svg-ikona"   src="images/onama.svg" alt="radio-roza" />POSLUŠAJTE EMISIJU
     </button>
     </a>

    </div>)
    }
  else {
    return( <div></div>)
  }

 }


  //Ispis galerije po slikama
  ispisGalerije(){
     if(this.state.slike) {
     return(
         this.state.slike.map(slika => (
            <div className="w3-col l3 m6" key={slika.id}>
              <div id={slika.image} 
                onClick={this.onClick} 
                 className="rukica galerija" 
                style={{backgroundImage: 'url(https://www.mar-com.hr/radio-davor/admin/products/galerija/uploads/' + slika.image+')'}}
               
               >
              </div>
            </div>
         ))
      );
     }
     else{
        return (
          <div>
            
            <p>Članak nema galeriju slika.</p>
          </div>
        );
     }
  }

  //Funkcija koja se poziva kada korisnik klikne na neku sliku
  //Vodi ga u prikaz galerije
  klikNaSliku(idSlike) {
     this.props.history.push('/galerija-'+this.state.id+'-'+idSlike);
  }

  //Renderiranje sadržaja
  render() {
    console.clear();


    return (
      <div>

         <Helmet>
             <title>{this.state.name}</title>
         </Helmet>

        <Navigacija />

          {/* HEADER */}
         <header className=" header-ne-naslovnica  w3-display-container w3-center" id="home">

                <div className="header w3-text-white ">
                    <h1 className="w3-xxxlarge w3-wide w3-animate-opacity " >{this.state.name}</h1>
                    <p className="w3-wide podnaslov-header">RADIO ROŽA - RADIO ZAJEDNICE</p><hr/>
                </div>
              
         </header>

         {/* ČLANAK */}
         <div className="w3-container  najave-padding">
           <div className="w3-row-padding">

              <div className="w3-col m12 l6">

              
                       
                     <div className="istaknuto">
                       <img className="novosti-gslika" src= {'https://www.mar-com.hr/radio-davor/admin/products/uploads/'+ this.state.image}  alt={this.state.name}  />
                    </div>
                       

              </div>


               <div className="w3-col m12 l6">
                    <div className="onama-tekst">
                          <p> {ReactHtmlParser(this.state.text)}</p>
                       </div> <hr/>
                         {this.showButtonStream()}
               </div>
          

          </div>
         </div>



        {/* GALERIJA SLIKA */}
         <div class="w3-container main-padding naslovnica-ploca"  id="about">
            <h2 class="">GALERIJA SLIKA:</h2><hr/>

                <div class="w3-row-padding w3-center">

                 {this.ispisGalerije()}


<div id="modal01" className="w3-modal w3-black" onClick={this.closeModal}>
  <span className="w3-button w3-xxlarge w3-black w3-padding-large w3-display-topright" title="Close Modal Image">×</span>
  <div className="w3-modal-content w3-animate-zoom w3-center w3-transparent w3-padding-64">
    <img id="img01" className="w3-image" alt="Radio Roža" />
    <p id="caption" className="w3-opacity w3-large"></p>
  </div>
</div>


              
                </div>
        </div>



      

      			
      				
                  
                  

                
                  
      	

           <Footer />
          </div>
      
    );
  }
}

export default Novost;
