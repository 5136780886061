import React, { Component } from 'react';
import Navigacija from './Navigacija';
import Footer from './Footer';
import {Helmet} from "react-helmet";
import { Link } from "react-router-dom";

//404 komponenta koja se prikaze ako korisnik ode na rutu koja ne postoji
class Page404 extends Component {

  //Pri učitavanju ide scroll na vrh
  componentDidMount() {
     window.scrollTo(0, 0);
  }

  //renderiranje sadržaja
  render() {
    return (
      <div>

         <Helmet>
             <title>RADIO ROŽA RIJEKA - 404</title>
         </Helmet>

        <Navigacija />

        
             <div className="w3-container main-padding naslovnica-ploca aktualno-naslovnica-padding"  id="about">
                <div className="w3-center w3-large"><br/> <br/>
                    <h1 className="super-heading">404 ERROR </h1><hr />
                    <h3>Reka sam ne može. Zaulutao si. <Link to="/">Vrati se na naslovnicu.</Link></h3>
                </div>
             </div>
          <Footer />
   
      </div>
    );
  }
}

export default Page404;
