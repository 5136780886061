import React, { Component } from 'react';
import Navigacija from './Navigacija';
import Footer from './Footer';
import {Helmet} from "react-helmet";
import ReactHtmlParser from 'react-html-parser';

//Komponenta za stranicu žiroskopa
class Odasiljac extends Component {

  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.state = {
      limit:100,
      podacastPlay: '',
      podcasts_skolica: "",
      podcasts_nevidljive: "",
      podcasts_sos: "",

      emisije: [
        { 
          name:"Projekt Odasiljač",  
          alias:"odasiljac", 
          udruga:"", 
          img:"",
          slogan:"",
          selected: true, 
          content:`
          <p>Projekt Odašiljač okuplja novinare iz tri partnerske 
          udruge: <b> Udruge Ri Rock, S.O.S. Rijeka i Pariter. </b> Cilj je izgradnja 
          stručnih kadrova koji će adekvatno izvještavati o ranjivim skupinama unutar 
          tri radijske emisije emitirane na riječkom radiju zajednice (Community radio) – Radio Roži. 
          Putem emisija javnost će biti informirana o trima temama: djeca i mladi; rodna ravnopravnost, 
          diskriminacija i ženska prava; žrtve nasilja. </p>

          <p><b>Ciljevi i očekivani rezultati projekta:</b></p>
          <p>Projektom Odašiljač stvorit će se resursni preduvjeti za:</p>

          <ul>
              <li> 1. jačanje stručnih kapaciteta medijskih djelatnika putem usavršavanja i osposobljavanja za medijsko izvještavanje, 
                  vezano uz rodnu ravnopravnost, rodno uvjetovano nasilje, žrtve nasilja, 
                  komunikaciju s djecom i mladima, pristup mladima s teškoćama u 
                  učenju te pristup mladima koji imaju traumatična iskustva 
                  (bullying, verbalno zlostavljanje), ciljeve održivog razvoja, 
                  medijskog pristupa djeci i mladima romske nacionalne manjine
              </li>
              <li>2. osiguranje stručnog, stalnog i stabilnog tima za razvoj programa</li>
              <li>3. stvaranje preduvjeta za održivo djelovanje</li>
              <li>4. povećanje vidljivosti</li>
              <li>5. proizvodnju i objavu programskog sadržaja radija</li>
          
          </ul>


          
          <p><b>Ukupna vrijednost projekta i iznos koji sufinancira EU (u HRK)</b></p>

          <p>Ukupna vrijednost projekta iznosi 1.385.072,28 HRK, od čega je 85% (1.177.311,438 HRK) 
          osigurano iz Europskog socijalnog fonda, dok je preostalih 15% (207.760,842 HRK) 
          osigurano iz Državnog proračuna Republike Hrvatske.</p>

          <p><b>Razdoblje provedbe projekta </b></p>
          <p> Od 21. kolovoza 2020. do 21. kolovoza 2022.</p>

          <p><b>Kontakt osoba za više informacija </b></p> 
          <p>Marina Jakšić, voditeljica projekta, +385 99 795 2969 </p>

          <p><b>Dodatno:</b></p> 

          <p class="eu-recenica"> 
            <a target="_blank" 
              rel="noopener noreferrer" 
              href="https://strukturnifondovi.hr/">
                www.strukturnifondovi.hr
            </a></br>
            <a target="_blank" 
              rel="noopener noreferrer" 
              href="http://www.esf.hr/operativni-program/">
                www.esf.hr
            </a></br>
          </p>


          <p class="eu-recenica"><b>Projekt je sufinancirala Europska unija iz Europskog socijalnog fonda.</b></p>


          
          `},
        { 
          name:"Emisija Nevidljive", 
          alias:"nevidljive", 
          udruga:"PaRiter",
          img:"nevidljive.png", 
          slogan:"Gdje priče žena postaju vidljive.",
          selected:false, 
          content:`<p> Obrađivat će teme iz domene rodne ravnopravnosti i 
          reproduktivne pravde. Kroz razgovore sa ženama pokrit će se aktualne teme 
          iz politike, kulture i ekonomije iz pozicije rodne ravnopravnosti te omogućiti 
          sudjelovanje ženama različitih profila, profesija i stupnja obrazovanja, 
          a sve kako bi se aktivno radilo na uključivanju socijalno osjetljivijih 
          skupina u javni i društveni život i kako bi se neispričanim pričama dala 
          platforma za zahtijevanje promjena. Cilj emisije je informirati širu 
          javnost o ženskim pravima te diskriminirajućim situacijama s kojima se 
          ženske osobe susreću u javnom i privatnom životu te osnažiti ih za 
          reagiranje na nepravdu i pokretanje promjena.` 
        },
        { 
          name:"Emisija Školica", 
          alias:"skolica", 
          udruga:"UDRUGA RI ROCK", 
          img:"skolica.png",
          slogan:"Mladi i mlađi, zanimljive priče o onima na kojima svijet ostaje.",
          selected:false, 
          content:`<p>Adresirat će probleme djece i mladih u različitoj dobi - 
          prve godine života, vrtić, predškolska dob, niži i viši razredi osnovne 
          škole, srednja škola te fakultetsko doba. Osim tema povezanih sa samim 
          školovanjem (bullying, poteškoće s učenjem), obradit će se i 
          teme vezane uz obiteljski život (problemi u ponašanju, zanemarivanost od strane 
          roditelja, popravne institucije) te svakodnevne aktivnosti 
          (pronalazak zaposlenja, hobiji). U emisiji će gostovati djeca i mladi 
          (s dozvolom roditelja/staratelja), predstavnici institucija, voditelji 
          izvannastavnih aktivnosti i dr. dionici čiji rad uključuje rad s djecom 
          i mladima. Ugostit će se gosti iz drugih županija kako bi se mogla 
          usporediti situacija PGŽ-a i ostatka Hrvatske. Cilj emisije je 
          osvijestiti javnost o problemima djece i mladih, njihovom pogledu na 
          svijet i percepciji stvarnosti. Bitno je javno progovarati o mladima i 
          njihovim pravima jer su oni budućnost, a problem s kojima se susreću mogu 
          izazvati trajne posljedice na njih i njihovo okruženje.</p>` },
        { 
          name:"Emisija S.O.S.", 
          alias:"sos", 
          udruga:"UDRUGA SOS RIJEKA - CENTAR ZA NENASILJE I LJUDSKA PRAVA", 
          img:"sos.png",
          slogan:"Teme o kojima se rijetko priča, a o kojima je bitno govoriti.",
          selected:false, 
          content:`<p>Obradit će se teme pružanja primarne pravne i psihološke pomoći žrtvama obiteljskog, partnerskog i seksualnog nasilja, pojedini aspekti radnog prava vezani uz nasilje i uznemiravanje na radnom mjestu te važnosti rada s mladima u svrhu prevencije i edukacije o nasilju. Omogućit će se da se žrtve nasilja zauzmu za sebe kroz progovaranje o svojim doživljajima te da „dobiju svoj glas“. Izlazak u javnost s vlastitom pričom na neke od njih djeluje terapeutski, ali je i iznimno korisno za druge osobe u sličnim situacijama i za društvo općenito. Predstavljanje neće biti intruzivno te pruža mogućnost zadržavanja anonimnosti. Gosti emisije bit će korisnici/e Udruge, stručnjaci/kinje i volonteri/ke koji s njima rade te istraživači/ce koje se bave temom socijalno marginaliziranih i ranjivih skupina.</p>` }
      ]
    };
}

  componentDidMount() {
     window.scrollTo(0, 0);
     this.dohvacanjePodcasta(); 
     this.printNavigationEmisije(); 
  }

executeScroll = () => this.myRef.current.scrollIntoView()

dohvacanjePodcasta = async ()  => {
  try {
    const result_skolica = await fetch("https://api.mixcloud.com/RadioRo%C5%BEa/playlists/skolica/cloudcasts/?limit=35");
    const podcasts_skolica = await result_skolica.json();
    this.setState ({ podcasts_skolica:podcasts_skolica.data.reverse() }); 
  } catch (error) {
      console.log(error)
  }

  try {
    const result_nevidljive = await fetch("https://api.mixcloud.com/RadioRo%C5%BEa/playlists/nevidljive/cloudcasts/?limit=35");
    const podcasts_nevidljive = await result_nevidljive.json();
    this.setState ({ podcasts_nevidljive:podcasts_nevidljive.data.reverse() }); 
  } catch (error) {
      console.log(error)
  }

  try {
    const result_sos = await fetch("https://api.mixcloud.com/RadioRo%C5%BEa/playlists/sos/cloudcasts/?limit=35");
    const podcasts_sos = await result_sos.json();
    this.setState ({ podcasts_sos:podcasts_sos.data.reverse() }); 
  } catch (error) {
      console.log(error)
  }

};

/*
dohvacanjePodcasta = async ()  => {
      try {
        const result_skolica = await fetch("https://api.mixcloud.com/RadioRo%C5%BEa/playlists/sound/cloudcasts/?limit=35");
        const podcasts_skolica = await result_skolica.json();
        const result_nevidljive = await fetch("https://api.mixcloud.com/RadioRo%C5%BEa/playlists/sound/cloudcasts/?limit=35");
        const podcasts_nevidljive = await result_nevidljive.json();
        const result_sos = await fetch("https://api.mixcloud.com/RadioRo%C5%BEa/playlists/sos/cloudcasts/?limit=35");
        const podcasts_sos = await result_sos.json();
       
        this.setState ({
          podcasts_skolica:podcasts_skolica.data.reverse(),
          podcasts_nevidljive:podcasts_nevidljive.data.reverse(),
          podcasts_sos:podcasts_sos.data.reverse()
        }); 
      } catch (error) {
          console.log(error)
        }
  };

  */

  ispisPodcasta(alias) {
    if(this.state[`podcasts_${alias}`]){
      return(
          this.state[`podcasts_${alias}`].map(podcast => (
   
            <div class="podcast__article" 
                 key= {podcast.key} 
                 id = {podcast.key} 
                 onClick={this.loadToPlay} >

                  <figure className="story__shape">
                        <img src={podcast.pictures.large} className="story__image" alt={podcast.name} />
                        <figcaption className="story__caption">
                              {podcast.name}
                              <img id="play" 
                                className="play-pause-img"
                                onClick={this.play} 
                                src="images/play-button.svg" 
                                alt="Radio Roža Play" />
                        </figcaption>
                  </figure>
              </div>
          ))
      );
    }
    else {
      return (
          <div className="container">
            <p className="super-paragraph">Sadržaj u pripremi. Stiže uskoro! :D</p>
          </div>
      );
    }
  }

  loadToPlay = async (el) => {
    const podcastKey = el.target.closest(".podcast__article").id;
    console.log(el.target.parentNode.parentNode);
    
    if (!this.state.podacastPlay) {
        await this.setState ({podacastPlay:podcastKey});
    } else {
        let widget = window.Mixcloud.PlayerWidget(document.getElementById("my-widget-iframe"));
        await widget.ready;
        await widget.pause();
        await this.setState ({podacastPlay:podcastKey});
    }
   }

printNavigationEmisije() {
    return (
        this.state.emisije.map(emisija => ( 
          <li className={`side-nav__item  ${emisija.selected ? "side-nav__item--active" : ""}`} 
              id  = {emisija.alias}
              key = {emisija.alias}
              onClick = {this.onSelectedEmisija}>
              <a className="side-nav__link" href="#odasiljac">
                  <span>{emisija.name}</span>
              </a>
          </li>
        ))
    );
}

onSelectedEmisija = async (e)  => {
  const alias = e.target.closest(".side-nav__item").id;
  await this.state.emisije.map(item => item.alias === alias 
    ? item.selected = true 
    : item.selected = false);
    this.executeScroll();
};

onSelectedIconEmisija = async (e)  => { 
  const alias = e.target.closest(".video-content__skolica").dataset.alias;
  const emisije = await this.state.emisije.map (item => {
    return ({...item, selected: item.alias === alias ? true : false});
  });
  
  await this.setState ({emisije}); 
  this.executeScroll();
};
   

printContentSelectedEmisija() {
    const emisija = this.state.emisije.find(emisija => emisija.selected);

    return (
       <div className="emisija" >
         <h2 className="emisija__title"><strong>{emisija.name}</strong></h2>
         <h4 className="emisija__udruga"><strong>{emisija.udruga}</strong></h4>
         {emisija.img ?
         <div className="emisija__photobox">
              <img className="emisija__photo" src={`images/${emisija.img}`} alt={emisija.name}/>
         </div>
         : <></>
         }


        {emisija.slogan ?
        <figure className="quote">
          <blockquote>
               "{emisija.slogan}"
          </blockquote>
          
        </figure>
        : <></>
        }

         <div class="emisija__content">{ReactHtmlParser(emisija.content)}</div><br/>

         {!emisija.udruga ?
            <img width="80%" src="images/eu_logosi_odasiljac.png" alt="Eu Logosi"/> 
         : <></>
         }
         
         {emisija.udruga ?
         <>
         <h2 className="emisija__title"><strong>Podcasts</strong></h2>
         <div className="podcast__box">
            {this.ispisPodcasta(emisija.alias)}
         </div>
         </>
          : <></>
        }

       </div>
     );
}


 
  render() {
    return (
      <div>

         <Helmet>
             <title>ODAŠILJAČ - RADIO ROŽA</title>
         </Helmet>

        <Navigacija />

         <header className="odasiljac-header">
              <img className="odasiljac-header__logo"  src="images/logo_odasiljac.svg" alt="Odašiljač - Radio Roža"/> 
              <p className="header__intro">Odašiljemo važne teme. Na radiju zajednice okupljamo medijske djelatnike, izgrađujemo stručne kadrove i progovaramo o ranjivim skupinama u društvu.</p>
         </header>

         <section className="section-video">
                <div className="bg-video">
                    <video className="bg-video__content" autoPlay muted loop>
                        <source src="images/video_mic.mp4" type="video/mp4"></source>
                        <source src="images/video.webm" type="video/webm"></source>
                        You browser is not supported!
                    </video>
                  </div>    
          </section>


          <main className="odasiljac" id="odasiljac">
              <nav className="odasiljac__sidebar">
                  <ul className="side-nav">
                      {this.printNavigationEmisije()} 
                  </ul>
              </nav>


              <div className="odasiljac__content" ref={this.myRef} >

              <div className="info__line"> PON · SRI · PET · 12:00h od 22.03.2021.</div>

              <div className="video-content">
                  <div data-alias="skolica" onClick={this.onSelectedIconEmisija} className="video-content__skolica">
                     <img width="50%" className="emisija-image" src="images/skolica.png" alt="Odašiljač - Radio Roža"/>  
                     <h3 className="video-content__name"><span>Emisija</span> Školica</h3>
                  </div> 
                  <div data-alias="sos" onClick={this.onSelectedIconEmisija} className="video-content__skolica">
                     <img width="50%" className="emisija-image" src="images/sos.png" alt="Odašiljač - Radio Roža"/> 
                     <h3 className="video-content__name"><span>Emisija</span> S.O.S.</h3>
                  </div> 
                  <div data-alias="nevidljive" onClick={this.onSelectedIconEmisija} className="video-content__skolica">
                     <img width="50%" className="emisija-image" src="images/nevidljive.png" alt="Odašiljač - Radio Roža"/> 
                     <h3 className="video-content__name"><span>Emisija</span> Nevidljive</h3>
                  </div> 
              </div>

               
              {this.printContentSelectedEmisija()}
              </div>
          </main>

      
          {this.state.podacastPlay === '' ? <></> :
          <div className="widget-container">
            <iframe id="my-widget-iframe"
              title="MixCloud Widget"
              width="100%" 
              height="60" 
              src={`https://www.mixcloud.com/widget/iframe/?hide_cover=1&mini=1&light=1&feed=${this.state.podacastPlay}`} 
              frameBorder="0" >
            </iframe>
          </div> 
        }
           
          <Footer />
        
      </div>
    );
  }
}

export default Odasiljac;
