import React, { Component } from 'react';
import Navigacija from './Navigacija';
import Footer from './Footer';
import {Helmet} from "react-helmet";

//Komponenta za stranicu žiroskopa
class Bitni17 extends Component {

  constructor(props) {
    super(props);
    this.state = {
      podcasts: [],
      slike: ["17-1.jpg", "17-2.jpg", "17-3.jpg", "17-4.jpg", "17-5.jpg", "17-6.jpg", 
        "17-7.jpg", "17-8.jpg", "17-9.jpg", "17-10.jpg"]
    };

    this.onClick = this.onClick.bind(this);
    this.closeModal = this.closeModal.bind(this);
}

  componentDidMount() {
     window.scrollTo(0, 0);
     this.dohvacanjePodcasta();  
  }

  onClick(event) {
    const urlSlika=event.target.id;
    console.log(urlSlika);
    document.getElementById("img01").src = "images/17/"+urlSlika;
    document.getElementById("modal01").style.display = "block";
}

 closeModal () {
  const modular = document.getElementById('modal01');
  modular.style.display = 'none';
  
 }

 //Ispis galerije po slikama
 ispisGalerije(){
  if(this.state.slike) {
  return(
      this.state.slike.map(slika => (
         <div className="w3-col l3 m6" key={slika}>
           <div id={slika} 
             onClick={this.onClick} 
             className="rukica galerija" 
             style={{backgroundImage: 'url(images/17/' + slika+')'}} >
           </div>
         </div>
      ))
   );
  }
  else{
     return (
       <div>
         
         <p>Članak nema galeriju slika.</p>
       </div>
     );
  }
}


 dohvacanjePodcasta = async ()  => {
      try {
        const result = await fetch("https://api.mixcloud.com/RadioRo%C5%BEa/playlists/17-bitnih/cloudcasts/?limit=20");
        const podcasts = await result.json();
        this.setState ({podcasts:podcasts.data.reverse()}); 
      } catch (error) {
          console.log(error)
        }
  };

ispisPodcasta() {
  if(this.state.podcasts){
    return(
        this.state.podcasts.map(podcast => (
                  
                  <a target="blank" key={podcast.key} href={podcast.url}>
                  <div className="w3-col l2 m12 w3-margin-bottom w3-row-padding" >
                      <div className="w3-card">
                      <img className="img-100 w3-hover-grayscale" 
                                  src={podcast.pictures.large} 
                                  alt={podcast.name} />
            
                            
                      </div>
                   </div>
                   </a>
        ))
    );
  }
  else {
    return(
        <div className="container">
          <p className="super-paragraph">Trenutno nema podcasta.</p>
        </div>
    );
  }
}
 
  render() {
    return (
      <div>

         <Helmet>
             <title>17 BITNIH - RADIO ROŽA</title>
         </Helmet>

        <Navigacija />

         <header className=" header-ne-naslovnica  w3-display-container w3-center" id="home">
            <div className="header w3-text-white ">
              <h1 className="w3-xxxlarge w3-wide w3-animate-opacity">17 BITNIH</h1>
              <p className="w3-wide podnaslov-header">RADIO ROŽA - RADIO ZAJEDNICE</p><hr/>
            </div>
         </header>

          {/* OPIS SERIJALA */}
          <div className="w3-container  najave-padding">
             <div className="w3-row-padding">
                  
                  <div className="w3-col m12 l12">
                    <div className="onama-tekst">

                    <img width="100%" src="images/main17.png" alt="17bitnih"/><hr/>

                    <h4>Kratki opis projekta: </h4>

                      <p>Radijska emisija "17 bitnih" okuplja 17 voditelja koji kroz 17 epizoda razgovaraju s gostima o 
                        ciljevima održivog razvoja. Emisija se emitira svakog utorka i petka točno u 
                        podne na Radio Roži. </p> 

                      <p>Nisu svi čuli za ciljeve održivog razvoja i zato se udruga Ri Rock prihvatila mikrofona, okupila 17 mladih voditelja i putem on-line streaminga odlučila ući u domove diljem hrvatske.</p>
                      
                      <p>Prije pet godina <b> Ujedinjeni narodi </b> su usvojili i svijetu predstavili <b>17 ciljeva održivog razvoja </b> sa željom da se oni postignu do 2030. godine. 
                        Hrvatska je kao jedna od članica UN-a aktivna u ostvarivanju ovih ciljeva od početka, 
                        a osnovano je i Nacionalno vijeće za održivi razvoj koje predlaže mjere, 
                        aktivnosti i prioritete te prati, analizira i koordinira njihovu provedbu. 
                        Evo koji su to ciljevi:</p>

                        <ul>
                          <li>1.	Iskorjenjivanje siromaštva (iskorijeniti siromaštvo svuda i u svim oblicima)</li>
                          <li>2.	Iskorjenjivanje gladi (iskorijeniti glad, postići sigurnost hrane i poboljšanu ishranu te promovirati održivu poljoprivredu)</li>
                          <li>3.	Zdravlje i dobrobit (osigurati zdrav život i promovirati blagostanje za ljude svih generacija)</li>
                          <li>4.	Kvalitetno obrazovanje (osigurati uključivo i kvalitetno obrazovanje te promovirati mogućnosti cjeloživotnog učenja)</li>
                          <li>5.	Rodna ravnopravnost (postići rodnu ravnopravnost i osnažiti sve žene i djevojke)</li>
                          <li>6.	Pitka voda i higijenski uvjeti (osigurati pristup pitkoj vodi za sve, održivo upravljati vodama te osigurati higijenske uvjete za sve)</li>
                          <li>7.	Pristupačna i čista energija (osigurati pristup pouzdanoj, održivoj i suvremenoj energiji po pristupačnim cijenama za sve)</li>
                          <li>8.	Dostojanstven rad i gospodarski rast (promovirati uključiv i održiv gospodarski rast, punu zaposlenost i dostojanstven rad za sve)</li>
                          <li>9.	Industrija, inovacije i infrastruktura (izgraditi prilagodljivu infrastrukturu, promovirati uključivu i održivu industrijalizaciju i poticati inovativnost)</li>
                          <li>10.	Smanjiti nejednakosti (smanjiti nejednakost unutar i između država)</li>
                          <li>11.	Održivi gradovi i održive zajednice (učiniti gradove i naselja uključivim, sigurnim, prilagodljivim i održivim</li>
                          <li>12.	Odgovorna potrošnja i proizvodnja (osigurati održive oblike potrošnje i proizvodnje)</li>
                          <li>13.	Odgovor na klimatske promjene (poduzeti hitne akcije u borbi protiv klimatskih promjena i njihovih posljedice)</li>
                          <li>14.	Život ispod vode (očuvati i održivo koristiti oceane, mora i morske resurse za održiv razvoj)</li>
                          <li>15.	Život na kopnu (zaštititi, uspostaviti i promovirati održivo korištenje kopnenih ekosustava, održivo upravljati šumama, suzbiti dezertifikaciju, zaustaviti degradaciju tla te spriječiti uništavanje biološke raznolikosti)</li>
                          <li>16.	Mir, pravda i snažne institucije (promovirati miroljubiva i uključiva društva za održivi razvoj, osigurati pristup pravdi za sve i izgraditi učinkovite, odgovorne i uključive institucije na svim razinama)</li>
                          <li>17.	Partnerstvo za ciljeve (ojačati načine provedbe te učvrstiti globalno partnerstvo za održivi razvoj)</li>
                        </ul>

                        <p>Radio Roža je radio zajednice (community radio) nastao iz želje i potrebe za praćenjem nezavisne kulturne scene i raznih kulturnih aktera u Rijeci, promocijom najrazličitijih (underground) žanrova, te ljubavi prema gramofonskim pločama. </p>
                        
                        <p><b>Glavni urednik Radio Rože, Davor Popdankovski kaže:</b></p>

                        <p><i>"Iako je prošlo već 5 godina od predstavljanja ciljeva, postoji veliki broj građana 
                          koji još uvijek nije čuo za ciljeve održivog razvoja. 
                          Šteta, jer kad bi svaka osoba s jednom malom gestom pridonijela 
                          ostvarenju ovih ciljeva, svijet bi do 2030. godine bi puno, 
                          puno ljepše mjesto za život. S radijskom emisijom "17 bitnih!" 
                          želimo povećati svijest o postojanju održivih ciljeva i želimo potaknuti 
                          svakog slušatelja da se uključi u globalni pokret"</i></p>

                      <p>Emisiju "17 bitnih!" možete poslušati uživo na Radio Roži <b> svakog utorka točno u podne </b>, a propuštene epizode mogu se poslušati niže na linku. </p>





                      <p>Dodatni materijal za proučavanje: -  
                          <strong>
                            <a target="_blank"  
                              rel="noopener noreferrer" 
                              href="https://sustainabledevelopment.un.org/"> 
                              https://sustainabledevelopment.un.org/
                            </a>
                          </strong>
                      </p>
                      <br/><br/>
                      <img  src="images/17_logos.png" alt="17 Bitnih"/><hr/>
                    </div>
                  </div>


                 

             </div>
         </div>

         <div className="w3-container "  id="team"> 
         <h2 className="w3-center w3-wide">EMISIJE: 17 BITNIH</h2> <hr/>
            
              <div className="w3-row-padding">
                  {this.ispisPodcasta()}
              </div><hr/>

          {/* PHOTO GALERIJA */}
         

<div class="w3-row-padding w3-center">
<h2 className="w3-wide">FOTO GALERIJA </h2><br/>
  {this.ispisGalerije()}                   
 

     <div id="modal01" className="w3-modal w3-black" onClick={this.closeModal}>
        <span className="w3-button w3-xxlarge w3-black w3-padding-large w3-display-topright" title="Close Modal Image">×</span>
       <div className="w3-modal-content w3-animate-zoom w3-center w3-transparent w3-padding-64">
        <img id="img01" className="w3-image" alt="Radio Roža" />
        <p id="caption" className="w3-opacity w3-large"></p>
       </div>
     </div>

</div>                



        </div>

        

        


          <Footer />
        
      </div>
    );
  }
}

export default Bitni17;
