import React, { Component } from 'react';
import Navigacija from './Navigacija';
import Footer from './Footer';
import {Helmet} from "react-helmet";

//Modul za galeriju
class Galerija extends Component {

  //Definiranje state-a
  constructor(props) {
    super(props);
    this.state = {
      slike: [],
      brojac: 0
     };
    this.ispisGalerije = this.ispisGalerije.bind(this);
    this.onPrethodna = this.onPrethodna.bind(this);
    this.onSljedeca = this.onSljedeca.bind(this);
  }

  //KAD SE KOMPONENTA MOUNTA DOHVAĆA GALERIJU
  componentDidMount() {
     window.scrollTo(0, 0);
     this.dohvacanjeGalerije();
  }

  //Funkcija koja sadrži api poziv za dohvaćanje galerije
  dohvacanjeGalerije() {
    fetch("https://www.mar-com.hr/radio-davor/rest/read_galerija.php?id=" + this.props.match.params.idNovosti)
      .then(res => res.json())
      .then(
        (result) => {

          var trazilica = result.records.find( slika => slika.id === this.props.match.params.idSlike );
          var brojac = result.records.findIndex(s => s.id === trazilica.id);

          this.setState({
            slike: result.records,
            brojac: brojac
          });
        },
        (error) => {
            console.log(error);
        });
  }

  //ISPIS GALERIJE SLIKA
  ispisGalerije() {
    var uloviTrenutnu = this.state.slike[this.state.brojac];
    if(uloviTrenutnu===undefined){
      console.log('Jos nema slika');
    }else{
    return(
       <div>
       <h3>{uloviTrenutnu.product_name}</h3>
       <img className="slika" src={"https://www.mar-com.hr/radio-davor/admin/products/galerija/uploads/"+uloviTrenutnu.image} alt="Slika galerije" />
       </div>
    );
  }
  }

  //Kada korisnik klikne na button za prethodnu sliku
  onPrethodna() {
    if(this.state.brojac===0){
      this.setState({
        brojac: this.state.slike.length-1
      });
    }else{
      this.setState({
        brojac: this.state.brojac-1
      });
  }
  }

  //Kada korisnik klikne na button za sljedeću sliku
  onSljedeca() {
    if(this.state.brojac===(this.state.slike.length-1)){
      this.setState({
        brojac: 0
      });
    }else{
      this.setState({
        brojac: this.state.brojac+1
      });
  }
  }

  //RENDER SADRŽAJA
  render() {

    return (
      <div>
         <Helmet>
             <title>GALERIJA SLIKA</title>
         </Helmet>
         <Navigacija />
         <div id="container">
            <canvas></canvas>
            <div id="overlay">
               
              <div className="content-white-novosti">
                <h1 className="super-heading">Galerija slika</h1>
                <button onClick={() => this.props.history.goBack()} className="btn btn-danger">Izlazak iz galerije slika</button>
                <hr/>
                <div className="text-center">
                  {this.ispisGalerije()}
                  <br/>
                  <button className="btn btn-primary margina-svuda" onClick={this.onPrethodna}>Prethodna slika</button>
                  <button className="btn btn-primary margina-svuda" onClick={this.onSljedeca} >Sljedeća slika</button>
                </div>
              </div>
              
              <Footer />
            </div>
          </div>
      </div>
    );
  }
}

export default Galerija;
