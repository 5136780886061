import React, { Component } from "react";
import Navigacija from "./Navigacija";
import Footer from "./Footer";
import { Helmet } from "react-helmet";

const MIXLOUD_API_URL = `https://api.mixcloud.com/RadioRo%C5%BEa/playlists/inkluzivni-kotač/cloudcasts/?limit=100`;

class InkluzivniKotac extends Component {
  constructor(props) {
    super(props);
    this.state = {
      podcasts: [],
      podacastPlay: "",
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.getShowsFromMixcloud();
  }

  getShowsFromMixcloud = () => {
    fetch(MIXLOUD_API_URL)
      .then((res) => res.json())
      .then((podcasts) => this.setState({ podcasts: podcasts.data.reverse() }))
      .catch((err) => console.log(err));
  };

  displayShows() {
    if (!this.state.podcasts) return <p>Trenutno nema podcasta.</p>;
    return this.state.podcasts.map((podcast) => (
      <article
        className="w3-card pointer"
        id={podcast.key}
        //data-link={podcast.id !== 0 ? null : podcast.url}
        onClick={this.loadToPlay}
        key={podcast.key}
      >
        <img
          className="img-100 w3-hover-grayscale"
          src={podcast.pictures.large}
          alt={podcast.name}
        />
        <div className="w3-padding-small">
          <p>{podcast.name}</p>
        </div>
      </article>
    ));
  }

  loadToPlay = async (el) => {
    const podcastKey = el.target.closest("article").id;

    if (!this.state.podacastPlay) {
      await this.setState({ podacastPlay: podcastKey });
    } else {
      let widget = window.Mixcloud.PlayerWidget(
        document.getElementById("my-widget-iframe")
      );
      await widget.ready;
      await widget.pause();
      await this.setState({ podacastPlay: podcastKey });
    }
  };

  render() {
    return (
      <>
        <Helmet>
          <title>INKLUZIVNI KOTAČ - RADIO ROŽA</title>
        </Helmet>
        <Navigacija />

        <header className="header-ne-naslovnica w3-center w3-padding-64 w3-margin-top w3-text-white">
          <h1>INKLUZIVNI KOTAČ</h1>
        </header>

        <main class="main-ctnr">
          <div className="w3-container w3-padding-24">
            <div className="w3-center">
              <img
                width="100%"
                src="images/kotac2.jpg"
                alt="INKLUZIVNI KOTAČ"
              />
            </div>
            <hr />

            <p>
              <b>
                Opći cilj projekta je jačanje građanske svijesti i angažmana u
                vezi romskih tema kroz inkluzivne prakse i poticanje pozitivnog
                diskursa o Romima, a specifični ciljevi su:
              </b>
            </p>
            <ul>
              <li>
                osnažiti mlade Rome uključivanjem u obrazovne, društvene,
                kulturno-umjetničke i poslovne procese,
              </li>
              <li>
                poboljšati novinarske kompetencije u izvještavanju o Romima i
                ostalim radnjivim skupinama kroz razvijanje novih metoda rada,
              </li>
              <li>
                međusektorska i unutar sektorska suradnja i sinergija u razvoju
                regionalnih politika usmjerenih na Rome
              </li>
            </ul>
            <p>
              <b>Ciljevi će se postići kroz aktivnosti:</b>
            </p>
            <ul>
              <li>edukacije budućih medijskih djelatnika,</li>
              <li>
                proizvodnje i objave medijskih sadržaja namijenjenih kvalitetnom
                izvještavanju o Romima (radijske emisije i časopis),
              </li>
              <li>
                organizacije kulturno – umjetničkih manifestacija posvećenih
                romskoj kulturi i umjetnosti,
              </li>
              <li>
                organizacije okruglog stola na kojem će se donijeti smjernice za
                daljnju međusektorsku i unutar sektorsku suradnju pri pisanju
                Akcijskog plana za uključivanje Roma u Primorsko-goranskoj
                županiji,
              </li>
              <li>
                radionice radijskog izvještavanja o ranjivim skupinama i
                proizvodnja medijskog sadržaja,
              </li>
              <li>
                radionice izrade Akcijskog plana za uključivanje Roma u
                Primorsko-goranskoj županiji
              </li>
            </ul>
            <p>
              <b>Ciljane skupine projekta su:</b>
            </p>
            <ul>
              <li>mladi Romi/Romkinje – novinari/novinarke,</li>
              <li>
                osobe romske nacionalnosti i osobe neromske nacionalnosti
                uključene u radionice,
              </li>
              <li>
                predstavnici lokalne i regionalne samouprave Primorsko-goranske
                županije uključena u radionice,
              </li>
              <li>organizacije civilnog društva,</li>
              <li>profitne ili neprofitne radijske postaje u RH</li>
            </ul>
            <p>
              <b>Kontakt osobe:</b>
            </p>
            <ul>
              <li>Martina Blečić, voditeljica projekta</li>
              <li>
                email: <b>inkluzivnikotac@gmail.com</b>
              </li>
            </ul>

            <ul>
              <li>Siniša - Senad Musić, koordinator projekta</li>
              <li>
                email: email: <b>smusic@romhr.hr</b>
              </li>
            </ul>
            <p>
              <b>Partneri u projektu:</b>
            </p>
            <img src="images/romhr_logo.png" alt="ROMHR" />
          </div>
          <div className="w3-container w3-center w3-padding-24">
            <h3>
              <b>Radioemisiju "Inkuzivni kotač" možete slušati na: </b>
            </h3>
            <a
              href="https://klfm.org/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img alt="KLFM" className="eu" src="images/klfm.png" />
            </a>

            <a
              href="http://radio.rojc.eu/"
              rel="noopener noreferrer"
              target="_blank"
            >
              <img alt="RADIO ROJC" class="eu" src="images/rojc.png" />
            </a>

            <a
              href="https://www.radiokastav.com/"
              rel="noopener noreferrer"
              target="_blank"
            >
              <img alt="RADIO KASTAV" class="eu" src="images/kastav.png" />
            </a>

            <a
              rel="noopener noreferrer"
              href="https://www.mixcloud.com/RadioRoza/playlists/inkluzivni-kota%C4%8D/"
              target="_blank"
            >
              <img alt="MIXCLOUD" class="eu" src="images/mix.png" />
            </a>
            <hr />

            <h2>RADIOEMISIJE: INKLUZIVNI KOTAČ</h2>
            <hr />
            <div className="grid-container">{this.displayShows()}</div>
          </div>
        </main>

        {this.state.podacastPlay === "" ? (
          <></>
        ) : (
          <div className="widget-container">
            <iframe
              id="my-widget-iframe"
              title="MixCloud Widget"
              width="100%"
              height="60"
              src={`https://www.mixcloud.com/widget/iframe/?hide_cover=1&mini=1&light=1&feed=${this.state.podacastPlay}`}
              frameBorder="0"
            ></iframe>
          </div>
        )}

        <Footer />
      </>
    );
  }
}

export default InkluzivniKotac;
