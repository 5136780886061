import React, { Component } from "react";
import { Link } from "react-router-dom";

//Komponenta navigacije
class Navigacija extends Component {
  onOpen() {
    var mySidebar = document.getElementById("mySidebar");
    if (mySidebar.style.display === "block") {
      mySidebar.style.display = "none";
    } else {
      mySidebar.style.display = "block";
    }
  }

  onClose() {
    var mySidebar = document.getElementById("mySidebar");
    mySidebar.style.display = "none";
  }

  showProjektiNav = () => {
    const element = document.querySelector(".nav-projekti");
    element.classList.toggle("vissible");
  };

  //renderiranje sadržaja
  render() {
    return (
      <div>
        <div className="w3-top">
          <div className="w3-bar  w3-card-4 w3-white " id="myNavbar">
            <Link className="logo-box" to="/">
              <img src="images/rr_favicon.png" alt="Radio Roža" /> RADIO{" "}
              <span className="fbold"> ROŽA </span>
            </Link>

            <div className="w3-right w3-hide-small w3-hide-medium">
              <Link
                to="/"
                id="home"
                className="w3-bar-item w3-button w3-hover-red w3-border-left w3-border-right"
              >
                Home
              </Link>

              <Link
                id="nama"
                to="/o-nama"
                className="w3-bar-item w3-button w3-hover-red w3-border-right"
              >
                O nama
              </Link>

              <Link
                id="novosti"
                onClick={this.klikNaLink}
                to="/novosti"
                className="w3-bar-item w3-button w3-hover-red w3-border-right"
              >
                Novosti
              </Link>

              <Link
                id="program"
                to="/program"
                className="w3-bar-item w3-button w3-hover-red w3-border-right"
              >
                Program
              </Link>

              <Link
                id="serijali"
                to="/serijali"
                className="w3-bar-item w3-button w3-hover-red w3-border-right"
              >
                Serijali
              </Link>

              <div className="w3-dropdown-hover">
                <div
                  id="projekti"
                  to="/o-nama"
                  className="w3-button w3-hover-red w3-border-right"
                >
                  PROJEKTI
                </div>

                <div className="w3-dropdown-content w3-bar-block w3-card-4">
                  <Link
                    id="ziroskop"
                    to="/ziroskop"
                    className="w3-bar-item w3-button w3-hover-red w3-border-right"
                  >
                    Žiroskop
                  </Link>

                  <Link
                    id="korona-kid"
                    to="/korona-kid"
                    className="w3-bar-item w3-button w3-hover-red w3-border-right"
                  >
                    Korona Kid
                  </Link>

                  <Link
                    id="bitni17"
                    to="/bitni17"
                    className="w3-bar-item w3-button w3-hover-red w3-border-right"
                  >
                    17 Bitnih
                  </Link>

                  <Link
                    id="odasiljac"
                    to="/odasiljac"
                    className="w3-bar-item w3-button w3-hover-red w3-border-right"
                  >
                    Odašiljač
                  </Link>

                  <Link
                    id="digitalne-rozice"
                    to="/digitalne-rozice"
                    className="w3-bar-item w3-button w3-hover-red w3-border-right"
                  >
                    Digitalne Rožice
                  </Link>

                  <Link
                    id="inkluzivni-kotac"
                    to="/inkluzivni-kotac"
                    className="w3-bar-item w3-button w3-hover-red w3-border-right"
                  >
                    Inkluzivni Kotač
                  </Link>
                </div>
              </div>

              <Link
                id="kontakt"
                to="/kontakt"
                className="w3-bar-item w3-button w3-hover-red w3-border-right"
              >
                Kontakt
              </Link>

              <a href="radio-roza.m3u">
                <button className="rr-botun fbold w3-animate-right">
                  <img
                    className="logo-light"
                    width="25px"
                    height="25px"
                    src="images/winamp.png"
                    alt="RR Download"
                  />{" "}
                  M3U
                </button>
              </a>

              <a href="radio-roza.xspf">
                <button className="rr-botun fbold w3-animate-right">
                  <img
                    className="logo-light"
                    width="25px"
                    height="25px"
                    src="images/vlc.png"
                    alt="RR Download"
                  />
                  XSPF
                </button>
              </a>
            </div>

            <button
              className="w3-bar-item w3-button w3-right w3-hide-large"
              onClick={this.onOpen}
            >
              <i className="fa fa-bars"></i>
            </button>
          </div>
        </div>

        <nav
          className="w3-sidebar w3-bar-block w3-black w3-card w3-animate-left w3-hide-large nav-display"
          id="mySidebar"
        >
          <button
            onClick={this.onClose}
            className="w3-bar-item w3-button w3-large w3-padding-16"
          >
            {" "}
            Zatvori ×
          </button>
          <Link to="/">
            {" "}
            <button className="w3-bar-item w3-button">Home </button>
          </Link>
          <Link to="/o-nama" className="w3-bar-item w3-button">
            {" "}
            O nama
          </Link>
          <Link to="/novosti" className="w3-bar-item w3-button">
            {" "}
            Novosti
          </Link>
          <div className="w3-bar-item w3-button" onClick={this.showProjektiNav}>
            {" "}
            PROJEKTI
            <img src="images/plus.svg" alt="Plus" className="nav-ext-icon" />
          </div>
          <div className="nav-projekti">
            <Link to="/ziroskop" className="w3-bar-item w3-button">
              {" "}
              Žiroskop
            </Link>
            <Link to="/korona-kid" className="w3-bar-item w3-button">
              {" "}
              Korona Kid
            </Link>
            <Link to="/bitni17" className="w3-bar-item w3-button">
              {" "}
              17 Bitnih
            </Link>
            <Link to="/odasiljac" className="w3-bar-item w3-button">
              {" "}
              Odašiljač
            </Link>
            <Link to="/digitalne-rozice" className="w3-bar-item w3-button">
              Digitalne Rožice
            </Link>
            <Link to="/inkluzivni-kotac" className="w3-bar-item w3-button">
              Inkluzivni kotač
            </Link>
          </div>
          <Link to="/program" className="w3-bar-item w3-button w3-hover-red">
            Program
          </Link>
          <Link to="/serijali" className="w3-bar-item w3-button w3-hover-red">
            Serijali
          </Link>
          <Link to="/kontakt" className="w3-bar-item w3-button">
            {" "}
            Kontakt
          </Link>
          <a href="radio-roza.m3u">
            <button className="rr-botun fbold w3-animate-right">
              <img
                className="logo-light"
                width="25px"
                height="25px"
                src="images/winamp.png"
                alt="RR Download"
              />{" "}
              M3U
            </button>
          </a>{" "}
          <br />
          <a href="radio-roza.xspf">
            <button className="rr-botun fbold w3-animate-right">
              <img
                className="logo-light"
                width="25px"
                height="25px"
                src="images/vlc.png"
                alt="RR Download"
              />
              XSPF
            </button>
          </a>
        </nav>
      </div>
    );
  }
}

export default Navigacija;
