import React, { Component } from "react";
import Footer from "./Footer";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Navigacija from "./Navigacija";

class Home extends Component {
  //Definiranje state-a
  constructor(props) {
    super(props);
    this.state = {
      novosti: [],
      najave: [],
      podcasts: [],
      podacastPlay: "",
    };
  }

  //Kada se komponenta učita ide api poziv za dohvaćanje svih novosti
  componentDidMount() {
    this.dohvacanjeNovosti();
    this.dohvacanjeNajave();
    this.menuActive();
    this.dohvacanjePodcasta();
    this.scroller();

    window.scrollTo(0, 0);
  }

  scroller = () => {
    const gap = 16;

    const carousel = document.getElementById("carousel"),
      content = document.getElementById("content"),
      next = document.getElementById("next"),
      prev = document.getElementById("prev");

    next.addEventListener("click", (e) => {
      carousel.scrollBy(width + gap, 0);
      if (carousel.scrollWidth !== 0) {
        prev.style.display = "flex";
      }
      if (content.scrollWidth - width - gap <= carousel.scrollLeft + width) {
        next.style.display = "none";
      }
    });
    prev.addEventListener("click", (e) => {
      carousel.scrollBy(-(width + gap), 0);
      if (carousel.scrollLeft - width - gap <= 0) {
        prev.style.display = "none";
      }
      if (!content.scrollWidth - width - gap <= carousel.scrollLeft + width) {
        next.style.display = "flex";
      }
    });

    let width = carousel.offsetWidth;
    window.addEventListener("resize", (e) => (width = carousel.offsetWidth));
  };

  dohvacanjeNovosti() {
    fetch("https://www.mar-com.hr/radio-davor/rest/read_all_naslovnica.php")
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            novosti: result.records,
          });
        },
        (error) => {
          console.log(error);
        }
      );
  }

  dohvacanjePodcasta = async () => {
    const result = await fetch(
      `https://api.mixcloud.com/RadioRo%C5%BEa/cloudcasts/?limit=10`
    );
    const podcasts = await result.json();
    console.log(podcasts.data);
    this.setState({ podcasts: podcasts.data });
  };

  loadToPlay = async (el) => {
    const podcastKey = el.target.closest("article").id;

    if (!this.state.podacastPlay) {
      await this.setState({ podacastPlay: podcastKey });
    } else {
      let widget = window.Mixcloud.PlayerWidget(
        document.getElementById("my-widget-iframe")
      );
      await widget.ready;
      await widget.pause();
      await this.setState({ podacastPlay: podcastKey });
    }
  };

  ispisPodcasta() {
    if (this.state.podcasts.length > 0) {
      return this.state.podcasts.map((podcast) => (
        <article
          className="hoverator"
          id={podcast.key}
          key={podcast.key}
          onClick={this.loadToPlay}
        >
          <img
            className="item w3-hover-grayscale"
            src={podcast.pictures.large}
            alt={podcast.name}
          />
          <div className="overlay">
            <div className="text">{podcast.name}</div>
            <img
              id="play"
              style={{ padding: "5px" }}
              width="40px"
              src="images/play-button.svg"
              alt="Radio Roža Play"
            />
          </div>
        </article>
      ));
    } else {
      return (
        <div className="container">
          <p className="super-paragraph">
            Serijal nema još podcasta. Mixamo kao ludi, bit će uskoro!
          </p>
        </div>
      );
    }
  }

  menuActive() {
    document.getElementById("home").classList.add("w3-red");
  }

  dohvacanjeNajave() {
    fetch("https://www.mar-com.hr/radio-davor/rest/read_najava.php")
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            najave: result.records,
          });
        },
        (error) => console.log(error)
      );
  }

  ispisNajave() {
    if (this.state.najave.length > 0) {
      return this.state.najave.map((najava, i) => (
        <div key={i}>
          <p>
            <span className="najava-datum">
              {najava.datum} {najava.mjesec}
            </span>
            :::
            <a
              className="crveni"
              target="_blank"
              rel="noopener noreferrer"
              href={najava.link}
            >
              {najava.name}
            </a>
          </p>
          <hr />
        </div>
      ));
    } else {
      return (
        <div className="container">
          <h3>Trenutno nema novih najava.</h3>
        </div>
      );
    }
  }

  //Funkcija za ispis novosti ako ih ima
  ispisNovosti() {
    if (this.state.novosti.length > 0) {
      return this.state.novosti.map((novost) => (
        <div className="card-news w3-card" key={novost.id}>
          <Link to={"/novost-" + novost.friendly_url}>
            <img
              width="100%"
              src={
                "https://www.mar-com.hr/radio-davor/admin/products/uploads/" +
                novost.image
              }
              alt={novost.name}
            />
          </Link>

          <div className="card-content">
            <Link to={"/novost-" + novost.friendly_url}>
              <h4>{novost.name}</h4>
            </Link>
            <p>{novost.opis}</p>
          </div>
        </div>
      ));
    } else {
      return (
        <div className="container">
          <h3>Učitavanje</h3>
        </div>
      );
    }
  }

  //Renderiranje sadržaja
  render() {
    return (
      <div>
        <Helmet>
          <title>RADIO ROŽA RIJEKA - INTERNET RADIO</title>
        </Helmet>

        <Navigacija />
        {/* HEADER */}

        <header
          className="bgimg-1 w3-display-container w3-center"
          id="home"
          style={{ minHeight: "100vh" }}
        >
          <div className="header w3-text-white ">
            <img width="55%" src="images/rr_logo.png" alt="Radio Roža" />
            <h1 className="w3-xxxlarge w3-wide w3-animate-opacity">
              RADIO ZAJEDNICE
            </h1>
            <p className="w3-wide podnaslov-header">INTERNET RADIO | RIJEKA </p>
            <hr />
          </div>
        </header>

        {/* INTRO */}
        <main className="main-ctnr">
          <div className="intro-container">
            <div className="w3-center w3-spin w3-hide-small w3-hide-medium">
              <img src="images/rec2.png" alt="RR Record" />
            </div>

            <p className="w3-center w3-large">
              Radio Roža je community internet radio nastao iz želje i potrebe
              za praćenjem nezavisne kulturne scene i raznih kulturnih aktera u
              Rijeci, promocijom najrazličitijih (underground) žanrova, te
              ljubavi prema gramofonskim pločama.
            </p>

            <div className="w3-row-padding w3-center mar-top-10">
              <Link to="/program">
                <button className="rr-botun-xl fbold w3-animate-right ">
                  <img
                    className="logo-light svg-ikona"
                    src="images/program.svg"
                    alt="tjedni-program"
                  />
                  TJEDNI PROGRAM
                </button>
              </Link>

              <Link to="/kako">
                <button className="rr-botun-xl fbold w3-animate-right ">
                  <img
                    className="logo-light svg-ikona"
                    src="images/podcasts.svg"
                    alt="radio-roza"
                  />
                  KAKO NAS MOŽETE SLUŠATI?
                </button>
              </Link>

              <Link to="/studio">
                <button className="rr-botun-xl fbold w3-animate-right ">
                  <img
                    className="logo-light svg-ikona"
                    src="images/onama.svg"
                    alt="podcasts"
                  />
                  STUDIO RADIO ROŽA
                </button>
              </Link>
            </div>
          </div>

          {/* NAJAVE */}

          <div className="home-container-najava">
            <div className="home-next-events">
              <h2>Najave:</h2>
              {this.ispisNajave()}
            </div>

            <div className="home-featured-article">
              <img width="100%" src="images/home_roza.png" alt="radio-roza" />
            </div>
          </div>
          <h2 className="">Novo na Radio Roži</h2>
          <hr />

          {/* AKTUALNO */}

          <div className="corausel-container">
            <div id="carousel">
              <div id="content">{this.ispisPodcasta()}</div>
            </div>
            <button id="prev">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <path fill="none" d="M0 0h24v24H0V0z" />
                <path d="M15.61 7.41L14.2 6l-6 6 6 6 1.41-1.41L11.03 12l4.58-4.59z" />
              </svg>
            </button>
            <button id="next">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <path fill="none" d="M0 0h24v24H0V0z" />
                <path d="M10.02 6L8.61 7.41 13.19 12l-4.58 4.59L10.02 18l6-6-6-6z" />
              </svg>
            </button>
          </div>

          <hr />
          <div className="home-news-container">{this.ispisNovosti()}</div>
        </main>

        {this.state.podacastPlay === "" ? (
          <></>
        ) : (
          <div className="widget-container">
            <iframe
              id="my-widget-iframe"
              title="MixCloud Widget"
              width="100%"
              height="60"
              src={`https://www.mixcloud.com/widget/iframe/?hide_cover=1&mini=1&light=1&feed=${this.state.podacastPlay}`}
              frameBorder="0"
            ></iframe>
          </div>
        )}

        <Footer />
      </div>
    );
  }
}

export default Home;
