import React, { Component } from "react";
import Navigacija from "./Navigacija";
import Footer from "./Footer";
import { Helmet } from "react-helmet";
import ReactHtmlParser from "react-html-parser";

class Serijal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      name: "",
      text: "",
      image: "",
      tag: "",
      limit: 100,
      podacastPlay: "",
      podcasts: [],
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.dohvacanjeSerijala();
  }

  dohvacanjeSerijala = async () => {
    try {
      const result = await fetch(
        "https://mar-com.hr/radio-davor/rest/read_one_serijal.php?friendly_url=" +
          this.props.match.params.friendly_url
      );
      const serijal = await result.json();
      await this.setState({
        id: serijal.id,
        name: serijal.name,
        text: serijal.text,
        image: serijal.image,
        tag: serijal.tag,
      });
      this.dohvacanjePodcasta();
    } catch (error) {
      console.log(error);
    }
  };

  dohvacanjePodcasta = async () => {
    let final_podcasts;
    const result = await fetch(
      `https://api.mixcloud.com/RadioRo%C5%BEa/playlists/${this.state.tag}/cloudcasts/?limit=${this.state.limit}`
    );
    const podcasts = await result.json();
    const br_podacasta = podcasts.data.length;
    final_podcasts = podcasts.data;
    if (br_podacasta === 100) {
      const result_page2 = await fetch(
        `https://api.mixcloud.com/RadioRo%C5%BEa/playlists/${this.state.tag}/cloudcasts/?limit=100&offset=100`
      );
      const podcasts_page2 = await result_page2.json();

      final_podcasts = podcasts.data.concat(podcasts_page2.data);
    }

    console.log(podcasts.data);
    this.setState({ podcasts: final_podcasts.reverse() });
  };

  loadToPlay = async (el) => {
    const podcastKey = el.target.closest("article").id;

    if (!this.state.podacastPlay) {
      await this.setState({ podacastPlay: podcastKey });
    } else {
      let widget = window.Mixcloud.PlayerWidget(
        document.getElementById("my-widget-iframe")
      );
      await widget.ready;
      await widget.pause();
      await this.setState({ podacastPlay: podcastKey });
    }
  };

  goToLink = async (el) => {
    const url = el.target.closest("article").dataset.link;
    window.location.href = url;
  };

  zivo_uzivo = [
    {
      id: 0,
      key: 5,
      name: "Živo uživo #15 - Turisti / Paper Tigers / Projekt",
      url: "https://www.youtube.com/watch?v=kxiafTX6FxQ&ab_channel=UdrugaRiRock",
      pictures: {
        large: "images/zz15.png",
      },
    },
    {
      id: 0,
      key: 4,
      name: "Živo uživo #14 - Witchguard / Hand Of Apollo / Entropist",
      url: "https://www.youtube.com/watch?v=TyaeXl6urOk&t=5748s&ab_channel=UdrugaRiRock",
      pictures: {
        large: "images/zz14.png",
      },
    },
    {
      id: 0,
      key: 3,
      name: "Živo uživo #13 - Krematorium | Zenoth",
      url: "https://www.youtube.com/watch?v=dBEEEtgavww&ab_channel=UdrugaRiRock",
      pictures: {
        large: "images/zz13.png",
      },
    },
    {
      id: 0,
      key: 2,
      name: "Živo uživo #12 - Silk Milk / Fanaa / The Siids",
      url: "https://www.youtube.com/watch?v=1_Yi3g7_cc8&ab_channel=UdrugaRiRock",
      pictures: {
        large: "images/zz12.png",
      },
    },
    {
      id: 0,
      key: 1,
      name: "Živo uživo #11 - E MOJ SALASIJE I EURIPID",
      url: "https://www.facebook.com/watch/live/?v=362446967984168&ref=watch_permalink",
      pictures: {
        large: "images/zz11.png",
      },
    },
    {
      id: 0,
      key: 0,
      name: "Živo uživo #10 - Marcellovo 2020",
      url: "https://www.facebook.com/watch/live/?v=1035119516844043&ref=watch_permalink",
      pictures: {
        large: "images/marcelovo.png",
      },
    },
  ];

  ispisPodcasta() {
    if (this.state.podcasts) {
      let podcasts = this.state.podcasts;
      if (this.state.tag === "živo-uživo") {
        podcasts = this.zivo_uzivo.concat(this.state.podcasts);
      }

      return podcasts.map((podcast) => (
        <article
          className="w3-card pointer"
          id={podcast.key}
          data-link={podcast.id !== 0 ? null : podcast.url}
          onClick={podcast.id !== 0 ? this.loadToPlay : this.goToLink}
          key={podcast.key}
        >
          <img
            className="img-100 w3-hover-grayscale"
            src={podcast.pictures.large}
            alt={podcast.name}
          />
          <div className="w3-padding-small">
            <p>{podcast.name}</p>
          </div>
        </article>
      ));
    } else {
      return (
        <div className="container">
          <p className="super-paragraph">
            Serijal nema još podcasta. Mixamo kao ludi, bit će uskoro!
          </p>
        </div>
      );
    }
  }

  render() {
    //console.clear();
    return (
      <div>
        <Helmet>
          <title>{this.state.name}</title>
        </Helmet>

        <Navigacija />

        <header className="header-hero">
          <div>
            <h1>{this.state.name}</h1>
            <p>RADIO ZAJEDNICE, RIJEKA</p>
          </div>
        </header>

        <div className="w3-container pozadina-spacer2 najave-padding">
          <div className="w3-row-padding">
            <div className="w3-col m12 l6">
              <div className="onama-tekst">
                <p> {ReactHtmlParser(this.state.text)} </p>
              </div>
            </div>

            <div className="w3-col m12 l6">
              <div className="istaknuto">
                <div className="w3-col m12 l12">
                  <img
                    className="istaknuto-slika"
                    src={
                      "https://www.mar-com.hr/radio-davor/admin/serijal/uploads/" +
                      this.state.image
                    }
                    alt={this.state.name}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <main className="main-ctnr">
          <div className="w3-container" id="team">
            <h2 className="w3-center w3-wide">PODCASTS</h2> <hr />
            <div className="grid-container">{this.ispisPodcasta()}</div>
            <hr />
          </div>
        </main>

        {this.state.podacastPlay === "" ? (
          <></>
        ) : (
          <div className="widget-container">
            <iframe
              id="my-widget-iframe"
              title="MixCloud Widget"
              width="100%"
              height="60"
              src={`https://www.mixcloud.com/widget/iframe/?hide_cover=1&mini=1&light=1&feed=${this.state.podacastPlay}`}
              frameBorder="0"
            ></iframe>
          </div>
        )}

        <Footer />
      </div>
    );
  }
}

export default Serijal;
