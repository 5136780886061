import React, { Component } from 'react';
import Navigacija from './Navigacija';
import Footer from './Footer';
import {Helmet} from "react-helmet";

//Komponenta za impressum
class Impresum extends Component {

  //Kad se učita srolla se na vrh
  componentDidMount() {
     window.scrollTo(0, 0);
  }

  //Renderiranje sadržaja
  render() {
    return (
      <div>

         <Helmet>
             <title>RADIO ROŽA RIJEKA - IMPRESSUM</title>
         </Helmet>

        <Navigacija />

          {/* HEADER */}
         <header className=" header-ne-naslovnica  w3-display-container w3-center" id="home">

                <div className="header w3-text-white ">
                    <h1 className="w3-xxxlarge w3-wide w3-animate-opacity " >IMPRESSUM </h1>
                    <p className="w3-wide podnaslov-header">RADIO ROŽA - COMMUNITY INTERNET RADIO</p><hr/>
                </div>
              
         </header>

         {/* IMPRESSUM - INTRO */}

         <div className="w3-container main-padding naslovnica-ploca"  id="about">

               <div className="w3-center w3-large">

                  <h3>Nakladnik:</h3><p>Udruga Ri Rock, Delta 5, 51000 Rijeka, Hrvatska</p>
                  <h3>Za nakladnika:</h3><p> Jelena Martinčić, jelena @leprinka.hr</p>

                  <h3>E - mail redakcije:</h3><p> radiorozari @gmail.com</p>

                  <h3>Glavni urednik:</h3><p> Davor Popdankovski, davor.poppy @gmail.com</p>

                  <h3>Redakcija:</h3><p> Sebastijan Tomažin, Davor Margan, Bruno Butorac</p>
                
                  <h3>Tehnička i WEB podrška:</h3>
                  <p>Davor Margan, (MAR - COM) davor @mar - com.hr </p>
                  <p>David Dubrović, (ANTERES) info @antares.hr</p>

              </div>

         </div>
         
          <Footer />
      </div>
    );
  }
}

export default Impresum;
