import React, { Component } from "react";
import { Link } from "react-router-dom";

//Footer modul
class Footer extends Component {
  render() {
    return (
      <footer>
        {/* ŽELIŠ IMATI EMISIJU */}

        <div className="w3-container w3-row w3-center red-gradient white-font w3-padding-64">
          <h3 className="w3-wide "> ŽELIŠ NAM SE PRIKLJUČITI? JAVI NAM SE!</h3>

          <Link to="/kontakt">
            <button className="rr-botun-xl-white fbold w3-animate-right ">
              <img
                className="svg-ikona"
                src="images/phone-black.svg"
                alt="kontakt"
              />{" "}
              POŠALJI NAM SVOJU IDEJU
            </button>
          </Link>
        </div>

        {/* FOOTER */}
        <div className="w3-center footer-pozadina w3-padding-64">
          <p className="footer-tekst">
            Projekt je sufinancirala Europska unija iz Europskog socijalnog
            fonda. <br />
            Izrada internet stranice sufinancirana je u okviru Operativnog
            programa <br />
            Učinkoviti ljudski potencijali iz Europskog socijalnog fonda.
            <br />
            <br />
            Sadržaj komunikacije isključiva je odgovornost Udruge Ri Rock.{" "}
            <br />
            <a className="crveni" href="https://strukturnifondovi.hr/">
              www.strukturnifondovi.hr
            </a>
            <br />
            <a className="crveni" href="http://www.esf.hr/"></a>
          </p>

          <div className="w3-xxxlarge w3-section ">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.facebook.com/radiorozha/"
            >
              <i className="fa fa-facebook-official w3-hover-opacity social-ikona"></i>
            </a>

            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.instagram.com/radio.rozari/"
            >
              <i className="fa fa-instagram w3-hover-opacity social-ikona"></i>
            </a>

            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.youtube.com/channel/UC9kyZZaaLJ-3y99cPACnKBw"
            >
              <i className="fa fa-youtube w3-hover-opacity social-ikona"></i>
            </a>

            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.mixcloud.com/RadioRo%C5%BEa/"
            >
              <i className="fa fa-mixcloud w3-hover-opacity social-ikona"></i>
            </a>

            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://twitter.com/radioroza?lang=en"
            >
              <i className="fa fa-twitter w3-hover-opacity social-ikona"></i>
            </a>
          </div>

          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.zamp.hr/"
          >
            <img src="images/zamp.png" alt="Zamp" width="90px" />
          </a>

          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.pgz.hr/"
          >
            <img
              src="images/pgz.svg"
              width="90px"
              alt="Primorsko goranska županija"
            />
          </a>

          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://min-kulture.gov.hr/"
          >
            <img
              src="images/mk.svg"
              width="70px"
              alt="Ministarstvo kulture i medija"
            />
          </a>

          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.rijeka.hr/"
          >
            <img
              style={{ marginLeft: "10px", marginBottom: "10px" }}
              src="images/grad.png"
              width="70px"
              alt="ACF"
            />
          </a>

          <p className="footer-tekst">
            © 2024 -{" "}
            <a className="crveni" href="https://www.mar-com.hr/">
              Mar-Com - Rijeka{" "}
            </a>
            , sva prava pridržana. Impressum se nalazi
            <Link className="crveni" to="/impressum">
              {" "}
              OVDJE{" "}
            </Link>
            , a pravila privatnosti{" "}
            <Link className="crveni" to="/privatnost">
              OVDJE.
            </Link>
          </p>

          <div className="mob-footer-padding"></div>
        </div>
      </footer>
    );
  }
}

export default Footer;
