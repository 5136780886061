import React, { Component } from 'react';
import Navigacija from './Navigacija';
import Footer from './Footer';
import {Helmet} from "react-helmet";

//Komponenta za stranicu žiroskopa
class Ziroskop extends Component {

   //Definiranje state-a
  constructor(props) {
      super(props);
      this.state = {
        podcasts: []
      };
  }
   
  //Kada se učita ide scroll na vrh stranice
  componentDidMount() {
     window.scrollTo(0, 0);
     this.dohvacanjePodcasta();
     this.menuActive();
  }

    menuActive(){
   document.getElementById("nama").classList.add('w3-red');
   document.getElementById("ziroskop").classList.add('w3-red');
  }



      dohvacanjePodcasta() {
      fetch("https://www.mar-com.hr/radio-davor/rest/read_serijal_podcast.php?friendly_url=ziroskop")
        .then(res => res.json())
        .then(
          (result) => {
            this.setState({
              podcasts: result.records
            });
            console.clear();
          },
          (error) => {
            console.log(error);
            console.clear();
          });
  }


  //Funkcija za ispis podcasta ako ih ima
  ispisPodcasta() {
    if(this.state.podcasts){
      return(
          this.state.podcasts.map(podcast => (
                    
                    <a target="blank" href={podcast.podcast_link}>
                    <div className="w3-col l2 m12 w3-margin-bottom w3-row-padding" key={podcast.id}>
                        <div className="w3-card">
                           <img className="img-100 w3-hover-grayscale" src={'https://www.mar-com.hr/radio-davor/admin/products/uploads/'+ podcast.image} alt={podcast.name} />
                               <div className="w3-container"><hr/>
                                   <p className="cart-naslov-visina-pod">{podcast.name}</p><hr/>  
                         
                               </div>
                        </div>
                     </div>
                     </a>
          ))
      );
    }
    else {
      return(
          <div className="container">
            <p className="super-paragraph">Trenutno nema podcasta.</p>
          </div>
      );
    }
  }

  
  //renderiranje sadržaja
  render() {
    return (
      <div>

         <Helmet>
             <title>RADIO ROŽA RIJEKA - ŽIROSKOP</title>
         </Helmet>

        <Navigacija />

         {/* HEADER */}
         <header className=" header-ne-naslovnica  w3-display-container w3-center" id="home">

                <div className="header w3-text-white ">
                    <h1 className="w3-xxxlarge w3-wide w3-animate-opacity " >ŽIROSKOP </h1>
                    <p className="w3-wide podnaslov-header">RADIO ROŽA - RADIO ZAJEDNICE</p><hr/>
                </div>
              
         </header>



          {/* ŽIROSKOP */}

         <div className="w3-container naslovnica-ploca"  id="about">
                
             <p>Projekt Žiroskop nastao je iz potrebe rješavanja problema nestabilnosti korištenja 
             prostornih resursa za sve izvaninstitucionalne aktere u kulturi, odnosno za svrhu ima razvoj 
             civilno-javnog partnerstva u upravljanju prostorima Filodrammatice, 
             Omladinskog kulturnog centra Palach i Hrvatskog kulturnog doma na Sušaku. 
             Ovo partnerstvo fokus stavlja na uspostavu zajedničkog upravljanja ovim 
             prostorima što uključuje razvoj modela upravljanja, uspostavljanje procedura 
             uključivanja svih aktera u kulturi grada i povećanu participaciju građana u 
             kulturnim aktivnostima, uključujući i njihovo programiranje. 
             Projekt doprinosi razvoju kapaciteta organizacija civilnog društva 
             i javne uprave za sudioničko upravljanje, jačanju socijalnog dijaloga kojemu je 
             svrha kvalitetnije upravljanje i uključivanju građana u upravljanje javnim resursima.</p><hr/>

             <p><strong>Korisnik: Drugo more</strong></p><hr/>

             <p><strong>Partneri: Savez udruga Molekula, Udruga Filmaktiv, Udruga Ri Rock, Udruga 
             Prostor plus i Grad Rijeka</strong></p><hr/>

             <p>Projekt „Žiroskop – Civilno-javno partnerstvo u upravljanju prostorima kulture u Rijeci“ 
             financiran je u okviru poziva Kultura u centru – potpora javno-civilnog partnerstva u kulturi,
              UP.04.2.1.04.0078 – Operativni program „Učinkoviti ljudski potencijali“ 2014. – 2020.</p><hr/>

             <p>Projekt ukupne vrijednosti 2.416.102,66 kuna je financiran iz Europskog socijalnog fonda 
             u iznosu od 2.053.687,26 kuna i sufinanciran od strane Ministarstva kulture RH u iznosu 
             od 362.415,40 kuna.</p><hr/>

             <p>Posrednička tijela u provedbi projekta su Ministarstvo kulture RH i 
             Nacionalna zaklada za razvoj civilnoga društva.</p><hr/>

             <p>Razdoblje provedbe projekta je od 29.10.2018. do 29.10.2020.</p><hr/>

             <p>Radio zajednice služi kao kanal komunikacije s građanima i 
             kao mehanizam uključivanja građana u rasprave o civilno-javnom partnerstvu, 
             programiranju, sudjelovanju u programima i refleksiji uspostavljenih procedura i 
             implementacije projekta. </p>

             <p>Kroz realizaciju ovog segmenta projekta Žiroskop 15 zaposlenika i članova 
             Udruga (članica Saveza udruga Molekula) sudjelovat će u aktivnostima jačanja 
             stručnog i iskustvenog kapaciteta potrebne za uspostavu tkv. Radija Zajednice; 
             10 članova organizacija civilnog društva sudjelovat će u aktivnostima jačanja kapaciteta 
             u području video produkcije potrebne za kreiranje multimedijalne podrške programa 
             Radija Rože; min. 4 organizacije civilnog društva sudjelovat će u aktivnostima jačanja 
             kapaciteta potrebnih za uspostavu multimedijalnog radija zajednice.  </p>

             <p>Radio Roža postaje centralno mjesto za stvarno emitiranje, 
             dokumentiranje i prijenos multimedijskih sadržaja te informiranje 
             zajednice o programskim aktivnostima DKC-a i udruga članica, 
             transverzalno povezuje različite aktere kulturnog i 
             civilnog društva te građana u smislu sudioničkog kreiranja i 
             emitiranja diferenciranih društvenih i kulturnih sadržaja te 
             podiže svijest o civilno-javnom partnerstvu i sudioničkom upravljanju. </p>

            <p><b>Kontakt osoba za projekt: Davor Popdankovski | +385 98 9134437 | radio.rozari@gmail.com</b></p>



         </div>

         <div className="w3-container "  id="team"> 
         <h2 className="w3-center w3-wide">PODCASTS: ŽIROSKOP</h2> <hr/>
            
              <div className="w3-row-padding">
                  {this.ispisPodcasta()}
              </div><hr/>
        </div>


          <Footer />
        
      </div>
    );
  }
}

export default Ziroskop;
