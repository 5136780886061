import React, { Component } from 'react';
import Navigacija from './Navigacija';
import Footer from './Footer';
import { Link } from "react-router-dom";
import {Helmet} from "react-helmet";

//Komponenta za stranicu o nama
class Onama extends Component {

  //Kada se učita ide scroll na vrh stranice
  componentDidMount() {
     window.scrollTo(0, 0);
     this.menuActive();
  }

   menuActive(){
   document.getElementById("nama").classList.add('w3-red');
  }

  //renderiranje sadržaja
  render() {
    return (
      <div>

         <Helmet>
             <title>RADIO ROŽA RIJEKA - O NAMA</title>
         </Helmet>
         <Navigacija />
          
        {/* HEADER */}
         <header className=" header-ne-naslovnica  w3-display-container w3-center" id="home">

                <div className="header w3-text-white ">
                    <h1 className="w3-xxxlarge w3-wide w3-animate-opacity " >RADIO ROŽA</h1>
                    <p className="w3-wide podnaslov-header">RADIO ZAJEDNICE, RIJEKA</p><hr/>
                </div>
              
         </header>

        {/* O NAMA - INTRO */}

         <div className="w3-container main-padding naslovnica-ploca"  id="about">

            <p className="w3-center w3-large">Radio Roža je community internet radio nastao iz želje i potrebe 
            za praćenjem nezavisne kulturne scene i raznih kulturnih aktera u Rijeci, 
            promocijom najrazličitijih (underground) žanrova, te ljubavi prema gramofonskim pločama.</p>

               <div className="w3-row-padding w3-center mar-top-10">

                 <Link to="/kako">
                 <button className="rr-botun-xl fbold w3-animate-right "> 
                 <img className="logo-light svg-ikona"   src="images/onama.svg" alt="radio-roza" />
                  KAKO NAS MOŽETE SLUŠATI?</button>
                 </Link>
                 
                 <Link to="/studio">
                 <button className="rr-botun-xl fbold w3-animate-right "> 
                 <img className="logo-light svg-ikona"   src="images/podcasts.svg" alt="podcasts" />
                  RADIO ROŽA STUDIO</button>
                 </Link>

                 <Link to="/crew">
                 <button className="rr-botun-xl fbold w3-animate-right "> 
                 <img className="logo-light svg-ikona"   src="images/podcasts.svg" alt="podcasts" />
                  RADIO ROŽA CREW</button>
                 </Link>

               </div>


         </div>


        {/* O NAMA - SEKCIJA 1 */}
         <div className="w3-container pozadina-spacer2 najave-padding">
             <div className="w3-row-padding">

                  <div className="w3-col m12 l6">

                     <h3 className="w3-wide ">
                        <img src="images/nota.svg" className="svg-ikona" alt="ikona-zivo" />
                        KAKAV SMO MI RADIO? </h3><div className="crta-black"> </div>

                      <div className="onama-tekst">

                          <p> Osnovan od strane nekolicine prijatelja i entuzijasta ponaprije iz zabave, 
                          ali i revolta prema komercijalnim sadržajima, istodobno se počelo djelovati kao 
                          internet radio, ali i kao grupa selektora koja s vinila vrti mahom ex yu funk, 
                          disco, rock, uz poneki izlet u reggae, dub, gipsy i mnoge druge vode. 
                          Ubrzo su uslijedili nastupi po riječkim klubovima, ulicama, sajmovima i kinima,
                          nekoliko festivala u regiji – Komšilook (Rijeka), Velvet festival (Krk), Trk u šumu (Platak), 
                          pa čak i u Domu za starije i nemoćne osobe Mali Kartec – Krk. </p>
                          <div className="crta-black"></div>

                          <p>Uz nastupe, počeli su se organizirati i prijenosi uživo s raznih koncerata i nastupa, 
                          te se polako razvijala potreba za prostorom za rad i stvaranjem radija zajednice. 
                          Nakon prijave i dobivanja projekta „Žiroskop - Civilno-javno partnerstvo u upravljanju 
                          prostorima kulture u Rijeci“ Radio Roža službeno postaje radio zajednice u sklopu 
                          Udruge Ri Rock, a zajedno s partnerima na projektu – Drugo More, Filmaktiv, Prostor +, 
                          Savez udruga Molekula i Grad Rijeka, započelo je novo poglavlje djelovanja gdje 
                          će glavna zadaća biti pratiti i stvarati sadržaj u prostorima Palacha, Filodrammatice i 
                          HKD-a na Sušaku.</p><div className="crta-black"> </div>

                      </div>

                  </div>


                  <div className="w3-col m12 l6">

                         <div className="istaknuto">
                              <div className="w3-col m12 l12">

                                 <p>Fotografija: Mara Prpić</p>
                                 <img className="istaknuto-slika onama-slika"  src="images/r1.png" alt="Radio Roža studio"/>

                              </div>
                         </div>
                  </div>

             </div>
         </div>

       {/* O NAMA - SEKCIJA 2 */}

       <div className="w3-container pozadina-spacer2-reverse najave-padding">
            <div className="w3-row-padding">

                  <div className="w3-col m12 l6">
                       <div className="istaknuto">
                              
                              <div className="w3-col m12 l12">
                                 <img className="istaknuto-slika onama-slika"  src="images/r2.png" alt="Radio Roža studio" />

                              </div>
 <p>Fotografija: Mara Prpić</p>
                        </div>
                  </div>



                  <div className="w3-col m12 l6">
                                 
                         <h3 className="w3-wide ">
                         <img src="images/nota.svg" className="svg-ikona" alt="ikona-zivo" />
                         GDJE SE NALAZI NAŠ STUDIO? 
                         </h3><div className="crta-black"></div>

                         <div className="onama-tekst">
                                     
                            <p> Radio svoj dom dobiva u Omladinskom kulturnom centru Palach, te od 
                            siječnja 2019. godine započinje s uređenjem i opremanjem prostora koji će 
                            služiti kao radio zajednice, promovirajući razne bendove, izvođače, selektore
                            i druge umjetnike koji djeluju ili gostuju u Rijeci. </p>
                            <div className="crta-black"></div>

                             <Link to="/studio">
                               <button className="rr-botun-xl fbold w3-animate-right "> 
                               <img className="logo-light svg-ikona"   src="images/podcasts.svg" alt="podcasts" />
                               POGLEDAJTE KAKO IZGLEDA NAŠ STUDIO</button>
                               </Link>

                          
                        </div>

                  </div>
            

            </div>
       </div>

         <Footer />
         
      </div>
    );
  }
}

export default Onama;
