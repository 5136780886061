import React, { Component } from 'react';
import Navigacija from './Navigacija';
import Footer from './Footer';
import {Helmet} from "react-helmet";



//Komponenta za prikaz podcasta
class Podcast extends Component {
   
   //Definiranje state-a
   constructor(props) {
        super(props);
        this.state = {
       podcastOriginalne: [],
       podcastFiltrirane: [],
       filterKategorija: '',
       filterSearch: ''
        };
     this.onSearchType = this.onSearchType.bind(this);
     this.onCategorySelect = this.onCategorySelect.bind(this);
     this.filterPodcast = this.filterPodcast.bind(this);
   }

   //Kada se komponenta učita ide api poziv za dohvaćanje svih podcasta
   componentDidMount(){
      window.scrollTo(0, 0);
      this.dohvacanjePodcasta(); 
      this.menuActive(); 
   }

  menuActive(){
   document.getElementById("podcast").classList.add('w3-red');
  }

  onOpenSearchMob(){
    var mySidebar = document.getElementById("pretraga");
    if (mySidebar.style.display === 'block') {
      mySidebar.style.display = 'none';
    } else {
      mySidebar.style.display = 'block';
    }
  }

  onCloseSearchMob(){
    var mySidebar = document.getElementById("pretraga");
    mySidebar.style.display = "none";
  }


   
    componentDidUpdate(prevProps, prevState){
    if (this.state.filterKategorija !== prevState.filterKategorija || this.state.filterSearch !== prevState.filterSearch) {
      this.filterPodcast();
    }
  }

     filterPodcast(){
    if(this.state.filterSearch === '' && this.state.filterKategorija === '') {
      this.setState({podcastFiltrirane: this.state.podcastOriginalne})
    }
    else if(this.state.filterSearch !== '' && this.state.filterKategorija === '') {
      const result = this.state.podcastOriginalne.filter(podcast => podcast.name.toUpperCase().includes(this.state.filterSearch.toUpperCase()));
      this.setState({podcastFiltrirane: result});
    }
    else if(this.state.filterSearch === '' && this.state.filterKategorija !== '') {
      const result = this.state.podcastOriginalne.filter(podcast => podcast.category_id===this.state.filterKategorija);
      this.setState({podcastFiltrirane: result});
    }
    else {
      const result = this.state.podcastOriginalne.filter(podcast => podcast.category_id===this.state.filterKategorija && podcast.name.toUpperCase().includes(this.state.filterSearch.toUpperCase()));
      this.setState({podcastFiltrirane: result});
    }


  }


  onSearchType(e) {
    this.setState({filterSearch: e.target.value})
  }

  onCategorySelect(e){
    this.setState({filterKategorija: e.target.value})
  }

  dohvacanjePodcasta() {
      fetch("https://www.mar-com.hr/radio-davor/rest/read_all_podcasts.php")
        .then(res => res.json())
        .then(
          (result) => {
            this.setState({
              podcastOriginalne: result.records,
              podcastFiltrirane: result.records
            });
            console.clear();
          },
          (error) => {
            console.log(error);
            console.clear();
          });
  }

  //Funkcija za ispis podcasta ako ih ima
  ispisPodcasta() {
    if(this.state.podcastFiltrirane){
      return(
          this.state.podcastFiltrirane.map(podcast => (
                    
                    <a target="blank" href={podcast.podcast_link}>
                    <div className="w3-col l2 m12 w3-margin-bottom w3-row-padding" key={podcast.id}>
                        <div className="w3-card">
                           <img className="img-100 w3-hover-grayscale" src={'https://www.mar-com.hr/radio-davor/admin/products/uploads/'+ podcast.image} alt={podcast.name} />
                               <div className="w3-container w3-center">
                                   <h5 className="cart-naslov-visina-pod ">{podcast.name}</h5><hr/>  
                                   
                                    
                         
                               </div>
                        </div>
                     </div>
                     </a>
          ))
      );
    }
    else {
      return(
          <div className="container">
            <p className="super-paragraph">Trenutno nema podcasta.</p>
          </div>
      );
    }
  }

   
  //Renderiranje sadržaja
  render() {

    return (
      <div>

         <Helmet>
             <title>RADIO ROŽA RIJEKA - PODCASTI</title>
         </Helmet>

         <Navigacija />

          {/* PRETRAGA MOBITEL */}
          <nav className="w3-sidebar w3-bar-block  w3-card w3-animate-left nav-display" id="pretraga" >
             <button onClick={this.onCloseSearchMob} className="w3-bar-item w3-button w3-large w3-padding-16">Zatvori ×</button>
   
                <div className="pretraga-mob">
                   <p><b>Pretraga novosti:</b></p>

                   <div>
                     <input className="w3-input w3-border" id="search" onChange={this.onSearchType} type="text" placeholder="Upišite naslov" />
                   </div><hr/>

                   <div>
                      <select onChange={this.onCategorySelect} class="w3-select w3-border" name="option">
                        <option value="">Sve kategorije</option>
                        <option value="20">Glazba</option>
                        <option value="24">Kultura i umjetnost</option>
                      </select>
                  </div>
                                 
                </div>
          </nav>

         {/* HEADER */}
         <header className="header-ne-naslovnica  w3-display-container w3-center" id="home">

              <div className="header w3-text-white ">
                   <h1 className="w3-xxxlarge w3-wide w3-animate-opacity " >PODCASTS </h1>
                   <p className="w3-wide podnaslov-header">RADIO ROŽA - RADIO ZAJEDNICE</p><hr/>
              </div>

         <button className="w3-button w3-right stickySearchMob w3-hide-large" onClick={this.onOpenSearchMob}>
           <i className="fa fa-search"></i>
        </button>

              
         </header>

          <div className=" w3-hide-small w3-hide-medium">        
          <div className=" w3-col m12 l12">
  
          <div className="w3-row-padding w3-center">

              <div className="w3-half">
                <input className="w3-input w3-border" id="search" onChange={this.onSearchType} type="text" placeholder="Pretražite podcaste po naslovu" />
              </div>

              <div className="w3-half">
                <select onChange={this.onCategorySelect} class="w3-select w3-border" name="option">
                  <option value="">Sve kategorije</option>
                  <option value="20">Glazba</option>
                  <option value="24">Kultura i umjetnost</option>
                </select>
              </div>
            </div>

          </div>
        </div>

         <div className="w3-container aktualno-naslovnica-padding"  id="team">
           
              <div className="w3-row-padding">
                  {this.ispisPodcasta()}
              </div><hr/>
       

        </div>


            <Footer />

       
      </div>
    );
  }
}

export default Podcast;
