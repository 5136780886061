import React, { Component } from 'react';
import Navigacija from './Navigacija';
import Footer from './Footer';
import {Helmet} from "react-helmet";

//Komponenta za stranicu privatnosti
class Privatnost extends Component {

  //Kada se učita ide scroll na vrh stranice
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  //renderiranje sadržaja
  render() {
    return (
      <div>

         <Helmet>
             <title>RADIO ROŽA RIJEKA - Pravila privatnosti</title>
         </Helmet>

         <Navigacija />

           {/* HEADER */}
         <header className=" header-ne-naslovnica  w3-display-container w3-center" id="home">

                <div className="header w3-text-white ">
                    <h1 className="w3-xxxlarge w3-wide w3-animate-opacity " >PRAVILA PRIVATNOSTI </h1>
                    <p className="w3-wide podnaslov-header">RADIO ROŽA - COMMUNITY INTERNET RADIO</p><hr/>
                </div>
              
         </header>

         {/* PRAVILA PRIVATNOSTI */}

         <div className="w3-container main-padding naslovnica-ploca"  id="about">

                 <p>Ovom Izjavom o privatnosti ( Privacy Statement ) kao Radio Roža želimo Vam potvrditi da su nam 
                 sve informacije vezane uz Vaše osobne podatke izuzetno važne i da zaštitu Vaših osobnih podataka 
                 smatramo vrlo ozbiljnom i tome pristupamo s velikom profesionalnom odgovornošću u skladu s Zakonom RH 
                 o zaštiti osobnih podataka.</p>

                 <p>U nastavku  teksta želimo vas upoznati s načinom prikupljanja Vaših osobnih 
                 podataka u interakciji preko slušanja radija, posjeta našoj WEB stranici, 
                 korištenja naše mobilne aplikacije i društvenih mreža,  
                 sudjelovanja u našim promotivnim projektima i nagradnim igrama ili 
                 bilo kojim drugim načinom na koji nam možete ustupiti vaše osobne podatke.  
                 Također, želimo vas upoznati s načinom korištenja i obrade, čuvanja, promjene i brisanja Vaših 
                 osobnih podataka kao i mogućnost kako da s nama stupite u kontakt.</p><br/>

                 <h3 className="w3-wide">1. ZAŠTO SMO USVOJILI PRAVILA PRIVATNOSTI?</h3><hr/>

                  <p> Putem ove Izjave želimo vas informirati  da smo  kreirali i usvojili 
                  Politiku Privatnosti  kako bi osigurali da je Radio Roža 
                  (Nakladnik: Udruga Ri Rock, Delta 5, 51000 Rijeka, Hrvatska)  usklađena sa zahtjevima:<br/></p>
                  
                  <p><ul>
                    <li>Važeće zakonske regulative za zaštitu i obradu osobnih podataka;</li>
                    <li>Štićenja svih osobnih podataka s kojima dolazimo u kontakt;</li>
                    <li>Otvorenost i transparentnost prema svim korisnicima i ispitanicima o 
                    tome kako pohranjujemo i procesiramo osobne podatke;</li>
                    <li>Edukacija i informiranja svih naših slušatelja i ispitanika;</li>
                    <li>Povećanje transparentnosti obrade osobnih podataka i komunikacije;</li>
                  </ul></p>

                 <hr/>


                 <h3 className="w3-wide">2. KOJE OSOBNE PODATKE PRIKUPLJAMO I ZAŠTO?</h3><hr/>

                 <p> <b>IP ADRESE: </b> Prikupljamo IP adrese posjetitelja kao 
                 i string korisničkog agenta preglednika. Prikupljeni navedeni podaci koriste se isključivo za 
                 bolju funkcionalnost rada WEB stranice i statističku analitiku te ne identificiraju osobne podatke. <br/></p>
                 <hr/>
                

                 

                 <h3 className="w3-wide">3. UGRAĐENI SADRŽAJ S DRUGIH WEB-STRANICA?</h3>
                 <p>Članci na ovoj web-stranici mogu sadržavati ugrađeni sadržaj 
                 (npr. video, slike, članke, itd.). Ugrađeni sadržaj sa drugih web-stranica ponaša se 
                 istovjetno kao da je posjetitelj posjetio tu drugu web-stranicu.</p>

                 <p>Ove web-stranice možda prikupljaju podatke o vama, koriste kolačiće, 
                 imaju ugrađeno dodatno praćenje od trećih strana, i nadgledaju vašu interakciju s tim ugrađenim 
                 sadržajem, uključujući praćenje vaše interakcije s ugrađenim sadržajem ako imate račun i 
                 prijavljeni ste na web-stranici.</p><hr/>

                <h3 className="w3-wide">4. S KIME DIJELIMO VAŠE PODATKE</h3>
                <p>Vaše podatke ne dijelimo.</p><hr/>

                <h3 className="w3-wide">5. MALOLJETNE OSOBE I ZAŠTITA OSOBNIH PODATAKA</h3>
                <p>Jasno naglašavamo da ne prikupljamo i samim time i ne  obrađujemo bilo kakve osobne podatke maloljetnih osoba bez 
                privole i odobrenja roditelja odnosno zakonskog zastupnika. Maloljetne osobe mogu slobodno posjetiti našu 
                web stranicu odnosno naše društvene mreže, ali pružanje bilo kakvih osobnih podataka  
                mora biti uz privolu roditelja ili zakonskog zastupnika. 
                Eventualni podaci koji su prikupljeni bez naše svjesne namjere, t
                emeljem zahtjeva roditelja ili pravnog zastupnika bit će stavljeni na uvid i izbrisani.</p><hr/>

                <h3 className="w3-wide">6. RAZDOBLJE ČUVANJA</h3>
                <p>Podaci se čuvaju onoliko dugo koliko je potrebno kako bi ispunili obveze i usluge a najdulje godinu dana, nakon čega će biti obrisani, 
                osim ako zakonom nije drugačije određeno ili ako je to definirano Ugovornim odnosom.</p>

                <p>Podatke ne možemo obrisati:
                  
                  <ul>
                    <li>a) Ukoliko su potrebni za izvršavanje ugovornih obaveza, ili drugih zakonskih zahtjeva (npr. Zakon o računovodstvu)</li>
                    <li>b) Ukoliko se podaci aktivno upotrebljavaju u procesima temeljem zakonite svrhe za obradu podataka.</li>
                  </ul>
                </p><hr/>


                <h3 className="w3-wide">7. PRAVO UVIDA I ISPRAVKE</h3>
                <p>U svakom trenutku imate pravo podnijeti zahtjev za uvid u osobne podatke koji su kod nas pohranjeni. 
                Imate pravo ispravke, dopune ili brisanja vaših osobnih podataka kada su nepotpuni, netočni ili neažurni. 
                Ukoliko to želite, dovoljno je uputiti pismeni zahtjev sigurnost na navedenu adresu. E-MAIL: radiorozari@gmail.com</p><hr/>

                <h3 className="w3-wide">8. KAKO NAS KONTAKTIRATI?</h3>
                <p>Ukoliko imate bilo kakvo pitanje koje je vezano uz prikupljanje 
                i obradu vaših osobnih podataka ili želite steći uvid u Vaše osobne  
                podatke koje posjedujemo s ciljem provjere, izmjene ili brisanja slobodno
                nam se obratite na slijedeću adresu: radiorozari@gmail.com</p><hr/>
                
                <h3>Nakladnik:</h3><p>Udruga Ri Rock, Delta 5, 51000 Rijeka, Hrvatska</p>






                 

             

         </div>



          <Footer />
        
      </div>
    );
  }
}

export default Privatnost;
