import React, { Component } from 'react';
import Navigacija from './Navigacija';
import Footer from './Footer';
import {Helmet} from "react-helmet";
import { Link } from "react-router-dom";

//Komponenta za prikaz liste novosti
class Novosti extends Component {

  //Definiranje state-a
  constructor(props) {
     super(props);
     this.state = {
       novostiOriginalne: [],
       novostiFiltrirane: [],
       filterKategorija: '',
       filterSearch: ''
     };
     this.onSearchType = this.onSearchType.bind(this);
     this.onCategorySelect = this.onCategorySelect.bind(this);
     this.filterNovosti = this.filterNovosti.bind(this);
  }

  //Kada se komponenta učita ide api poziv za dohvaćanje svih novosti
  componentDidMount(){
     window.scrollTo(0, 0);
     this.dohvacanjeNovosti();
     this.menuActive();
  }

  componentDidUpdate(prevProps, prevState){
    if (this.state.filterKategorija !== prevState.filterKategorija || this.state.filterSearch !== prevState.filterSearch) {
      this.filterNovosti();
    }
  }

  menuActive(){
   document.getElementById("novosti").classList.add('w3-red');
  }


  filterNovosti(){
    if(this.state.filterSearch === '' && this.state.filterKategorija === '') {
      this.setState({novostiFiltrirane: this.state.novostiOriginalne})
    }
    else if(this.state.filterSearch !== '' && this.state.filterKategorija === '') {
      const result = this.state.novostiOriginalne.filter(novost => novost.name.toUpperCase().includes(this.state.filterSearch.toUpperCase()));
      this.setState({novostiFiltrirane: result});
    }
    else if(this.state.filterSearch === '' && this.state.filterKategorija !== '') {
      const result = this.state.novostiOriginalne.filter(novost => novost.category_id===this.state.filterKategorija);
      this.setState({novostiFiltrirane: result});
    }
    else {
      const result = this.state.novostiOriginalne.filter(novost => novost.category_id===this.state.filterKategorija && novost.name.toUpperCase().includes(this.state.filterSearch.toUpperCase()));
      this.setState({novostiFiltrirane: result});
    }


  }

  onSearchType(e) {
    this.setState({filterSearch: e.target.value})
  }

  onCategorySelect(e){
    this.setState({filterKategorija: e.target.value})
  }


  onOpenSearchMob(){
    var mySidebar = document.getElementById("pretraga");
    if (mySidebar.style.display === 'block') {
      mySidebar.style.display = 'none';
    } else {
      mySidebar.style.display = 'block';
    }
  }

  onCloseSearchMob(){
    var mySidebar = document.getElementById("pretraga");
    mySidebar.style.display = "none";
  }

  dohvacanjeNovosti() {
    fetch("https://www.mar-com.hr/radio-davor/rest/read_all_novosti")
      .then(res => res.json())
      .then(
      (result) => {
        this.setState({
          novostiOriginalne: result.records,
          novostiFiltrirane: result.records
        });
        console.clear();
      },
      (error) => {
        console.log(error);
        console.clear();
        });
  }


  //Funkcija za ispis novosti ako ih ima
  ispisNovosti() {
    if(this.state.novostiFiltrirane){
      return(
          this.state.novostiFiltrirane.map(novost => (
            <div className="card-news w3-card" key={novost.id}>
              
            <Link to={'/novost-'+novost.friendly_url}>
                <img width="100%" src= {'https://www.mar-com.hr/radio-davor/admin/products/uploads/'+ novost.image}  alt={novost.name}  />
            </Link>
           
           <div className="card-content">
                <Link to={'/novost-'+novost.friendly_url}>
                    <h4>{novost.name}</h4>
                </Link>
                <p>{novost.opis}</p>
           </div>
        </div>
             
          ))
      );
    }
    else {
      return(
          <div className="container">
            <h3>Učitavanje</h3>
          </div>
      );
    }
  }


  //renderiranje sadržaja
  render() {
    return (
      <div>

         <Helmet>
             <title>RADIO ROŽA RIJEKA - NOVOSTI</title>
         </Helmet>
        <Navigacija />

         {/* HEADER */}
         <header className=" header-ne-naslovnica  w3-display-container w3-center" id="home">

                <div className="header w3-text-white ">
                    <h1 className="w3-xxxlarge w3-wide w3-animate-opacity " >NOVOSTI </h1>
                    <p className="w3-wide podnaslov-header">RADIO ROŽA - RADIO ZAJEDNICE</p><hr/>
                </div>
              
         </header>

         <main className="main-ctnr">

            <input className="w3-input w3-border" 
                   id="search" onChange={this.onSearchType} 
                   type="text" 
                   placeholder="Pretražite novosti po naslovu" /><hr/>
                   
            <div className="home-news-container">
              {this.ispisNovosti()}
            </div>

         </main>

            <Footer />
      </div>
    );
  }
}

export default Novosti;
