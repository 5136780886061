import React, { Component } from 'react';
import Navigacija from './Navigacija';
import Footer from './Footer';
import {Helmet} from "react-helmet";
import moment from 'moment';
//import 'moment/locale/hr'
//moment.locale('hr')

class Program extends Component {

  constructor(props) {
     super(props);
     this.state = {
       today: moment(),
       today_str: {long:moment().format('dddd'), short: moment().format('ddd')},
       day_strShort_e: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
       day_strLong_e: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
       day_strLong_hr: ['Ponedjeljak', 'Utorak', 'Srijeda', 'Četvrtak', 'Petak', 'Subota', 'Nedjelja'],
       Mon: [],
       Tue: [],
       Wed: [], 
       Thu: [], 
       Fri: [], 
       Sat: [], 
       Sun: [],  
     };     
  }
   
  componentDidMount() {
     window.scrollTo(0, 0); 
     this.getEventsFromGoogle();
     this.activeButtons();
  }


  activeButtons(){
    document.getElementById("program").classList.add('w3-red');
  }


  getEventsFromGoogle = async () => {
    try {
      
      const today = moment();

      const start_of_week = today.startOf('isoWeek').format('YYYY-MM-DD');
      const end_of_week = today.startOf('isoWeek').add(7, 'days').format('YYYY-MM-DD');
      
      const startWeekDate = `${start_of_week}T00%3A00%3A00Z`;
      const endWeekDate = `${end_of_week}T00%3A00%3A00Z`;
      const calendarData = await fetch(`https://www.googleapis.com/calendar/v3/calendars/ab0mqfrpq49tvppncs1e9sjmmo%40group.calendar.google.com/events?singleEvents=true&timeMax=${endWeekDate}&timeMin=${startWeekDate}&key=AIzaSyA-8Ym27lnUQNEWfDN2cRIqapQUeaLJo6E`);
      const result = await calendarData.json();

      console.log(result);
      

      const events = result.items.map(event => {
        return {
          title:event.summary,
          day:moment(event.start.dateTime).format("dddd"),
          show_start:moment(event.start.dateTime).format("HH:mm"),
          schedule_date: moment(event.start.dateTime).format("Do MMMM YYYY"),
          full_date: event.start.dateTime    
        };
      });

      console.log(events);
      
      const weekEvents = await this.filterWeekEvents(events);
      const sortedWeekEventsByDate = await this.sortEventsByDate(weekEvents);
      this.filterEventsByDay(sortedWeekEventsByDate); 
          

    } catch (error) {
      console.log(error);
      
    }
  };

  filterWeekEvents = async (events) => {
    const weekEvents = await events.filter(date => moment(date.full_date).isSame(this.state.today, 'isoWeek'));
    return weekEvents;
  };

  sortEventsByDate = (events) => {
    const sortedEvents = events.sort((a,b) => {
      return new Date(a.full_date) - new Date(b.full_date);
    });
    return sortedEvents;
  };

  filterEventsByDay = async (events) => {
    await this.state.day_strLong_e.forEach((element, index) => {
      const dayEvents = events.filter(event => event.day === element); 
      this.setState({ [this.state.day_strShort_e[index]]: dayEvents });
    });
 
};


printProgramTabs = () => {
  const tabs = [];

  this.state.day_strLong_e.forEach((day, index) => {
    tabs.push(
      <button 
        key= {`d_btn${index}`}
        id={this.state.day_strShort_e[index]}
        className= {`w3-bar-item w3-button tablink ${day === this.state.today_str.long ? 'w3-red' : ''}`}
        onClick = {this.changeDay}
      > {this.state.day_strLong_hr[index]}             
     </button>
    );   
  });
  
  return tabs;

};

changeDay = (e) => {
  const day = e.target.id;
  const index = this.state.day_strShort_e.indexOf(day);
  const updatedToday = {long: this.state.day_strLong_e[index], short:day}
  this.setState({ today_str: updatedToday }); 
}

printProgramByDay =  () => {
  const day =  this.state.today_str.short;
  const index = this.state.day_strShort_e.indexOf(day);
  
  const datum = this.state.today.startOf('isoWeek').add('days', index).format("DD.MM.YYYY.");
  console.log(datum);

  
  
  if(this.state[day].length>0){
    return(       
      <div className="w3-container">
        <h3 className="w3-wide naslov-program text-upper">
            {`${this.state.day_strLong_hr[this.state.day_strShort_e.indexOf(day)]} -
            ${datum}`}
        </h3><hr/>
        
        {this.state[day].map(d => (
          <div key={d.full_date}> 
            <div className="w3-col m12 l2 programRed" > {d.show_start} h </div> 
            <div className="w3-col m12 l10 programNaziv">{d.title} </div>       
          </div>
        ))}
         
      </div>
    );
  } else {
    return ( <p>Program u pripremi</p>  );
  };

};

  
  render() {
    return (
      <div>

         <Helmet>
             <title>RADIO ROŽA RIJEKA - PROGRAM</title>
         </Helmet>

        <Navigacija />

         {/* HEADER */}
         <header className=" header-ne-naslovnica  w3-display-container w3-center" id="home">

                <div className="header w3-text-white ">
                    <h1 className="w3-xxxlarge w3-wide w3-animate-opacity " >TJEDNI PROGRAM </h1>
                    <p className="w3-wide podnaslov-header">RADIO ROŽA - RADIO ZAJEDNICE</p><hr/>
                </div>    
         </header>
          
         {/* TABS  */}
          <div className="w3-bar w3-black">
          {this.printProgramTabs().map(tab => {
            return tab;
          })}
          </div>
       
         {/* PROGRAM */}
          <div className="w3-container main-padding naslovnica-ploca"  id="about">
            <div className="w3-row-padding">
            {this.printProgramByDay()}
           </div>
          </div>
      
          <Footer />
        
      </div>
    );
  }
}

export default Program;
