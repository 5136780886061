import React, { Component } from "react";
import Navigacija from "./Navigacija";
import Footer from "./Footer";
import { Helmet } from "react-helmet";

//Komponenta za stranicu žiroskopa
class KoronaKid extends Component {
  constructor(props) {
    super(props);
    this.state = {
      podcasts: [],
    };
  }

  //Kada se učita ide scroll na vrh stranice
  componentDidMount() {
    window.scrollTo(0, 0);
    this.dohvacanjePodcasta();
    //this.menuActive();
  }

  /*
 menuActive(){
   document.getElementById("korona-kid").classList.add('w3-red');
   document.getElementById("projekti").classList.add('w3-red');
  }
  */

  dohvacanjePodcasta() {
    fetch(
      "https://www.mar-com.hr/radio-davor/rest/read_serijal_podcast.php?friendly_url=korona-kid"
    )
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            podcasts: result.records,
          });
          console.clear();
        },
        (error) => {
          console.log(error);
          console.clear();
        }
      );
  }

  //Funkcija za ispis podcasta ako ih ima
  ispisPodcasta() {
    if (this.state.podcasts) {
      return this.state.podcasts.map((podcast) => (
        <a target="blank" href={podcast.podcast_link}>
          <div
            className="w3-col l2 m12 w3-margin-bottom w3-row-padding"
            key={podcast.id}
          >
            <div className="w3-card">
              <img
                className="img-100 w3-hover-grayscale"
                src={
                  "https://www.mar-com.hr/radio-davor/admin/products/uploads/" +
                  podcast.image
                }
                alt={podcast.name}
              />
              <div className="w3-container">
                <hr />
                <p className="cart-naslov-visina-pod">{podcast.name}</p>
                <hr />
              </div>
            </div>
          </div>
        </a>
      ));
    } else {
      return (
        <div className="container">
          <p className="super-paragraph">Trenutno nema podcasta.</p>
        </div>
      );
    }
  }

  render() {
    return (
      <div>
        <Helmet>
          <title>KORONA KID - RADIO ROŽA</title>
        </Helmet>

        <Navigacija />

        {/* HEADER */}
        <header
          className=" header-ne-naslovnica  w3-display-container w3-center"
          id="home"
        >
          <div className="header w3-text-white ">
            <h1 className="w3-xxxlarge w3-wide w3-animate-opacity ">
              KORONA KID
            </h1>
            <p className="w3-wide podnaslov-header">
              RADIO ROŽA - RADIO ZAJEDNICE
            </p>
            <hr />
          </div>
        </header>

        {/* KORONA KID */}

        <div className="w3-container naslovnica-ploca" id="about">
          <div className="w3-center">
            <img width="30%" src="images/kk_logo.png" alt="Korona Kid" />

            <p>
              Interaktivni, tehnološki napredni, ali istovremeno zabavni načini
              učenja, pokazali su svoju važnost u novoj stvarnosti koju nam je{" "}
              <br /> stvorila pandemija korone. Kako bismo učinili sve da naša
              djeca i mladi što lakše shvate, a onda se i prilagode novonastaloj
              situaciji, <br /> pokrenuli smo projekt <b> Korona kid. </b>
            </p>
            <hr />
          </div>

          <p>
            <b>
              Projekt Korona kid provodimo sa svojim višegodišnjim partnerima,
              Udrugom za razvoj umjetnosti, kulture i edukacije - RUKE - (Vijeće
              mladih Benčić) i Gradskom knjižnicim Rijeka, a cilj projekta je{" "}
            </b>{" "}
            poticanje djece i mladih na građanski angažman i brigu za druge kroz
            usvajanje novih znanja i vještina građanskoga odgoja, primjenu novih
            tehnologija i socijalizaciju! Vijeće mladih Benčić (Udruga RUKE)
            nositelj je i koordinator projekta kojem doprinosi širokim iskustvom
            i znanjima u domeni neformalnoga obrazovanja djece i mladih, a
            partner Gradska knjižnica Rijeka donosi nam znanja vezana za
            kreiranje i uređivanje sadržaja, te Udruga Ri Rock znanja vezana za
            snimanja i montažu zvuka.
          </p>

          <p>
            Naši brojni stručni suradnici vrijedno rade na člancima posebno
            prilagođenima za djecu i mlade, kojima punimo naš portal{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://brickzine.hr/"
            >
              <b style={{ color: "red" }}> Brickzine.hr. </b>{" "}
            </a>{" "}
            Edukativni i zanimljivi tekstovi pokrivaju područja kulture,
            umjetnosti, građanskog odgoja, psihologije, kulture „uradi sam“…{" "}
          </p>
          <hr />

          <p>
            Ono što cijelu stvar čini još uzbudljivijom jest način na koji smo
            osigurali da baš svatko može uživati i konzumirati naš sadržaj. Sve
            spomenute članke ćemo, kroz naše online radionice, pretvoriti u
            uzbudljive radijske emisije koje će se moći pronaći i slušati na
            portalu Brickine.hr, ali također i na stranicama Vijeća mladih
            Benčić te na Radiju Roži.
          </p>

          <p>
            Tijekom{" "}
            <b>
              {" "}
              osam online radionica, članke će audio digitalizirati desetoro
              djece i mladih pod mentorstvom stručnih suradnika.{" "}
            </b>{" "}
            Audio digitalizacija provodit će se putem individualnih i grupnih
            online radionica koje će se održavati putem platforme Zoom. Vježbat
            ćemo govor, dikciju i izgovaranje (dramatizacija) tekstova pod
            mentorstvom riječke glumice – Petre Šarac. Nakon vježbe slijede
            snimanje govora i interpretacija tekstova koji će se također raditi
            online, a potom slijedi i prikupljanje zvukova i efekata koji
            ilustriraju događaje iz tekstova. Nakon radionice, audio snimke će
            montirati partner Udruga Ri Rock u konačan oblik podcasta/radijske
            emisije koja će zatim postati dostupna online svim našim čitateljima
            i slušateljima. Kako bismo naše mlade slušatelje i čitatelje dodatno
            angažirali, sudionici naših radionica će u svakoj radijskoj emisiji
            osmisliti po jedan poziv na akciju kako bismo prikupili reakcije i
            kreativne radove naših slušatelja i čitatelja i uključili ih u
            buduće emisije i objave tijekom projekta!
          </p>

          <p>
            Korona kid će do kraja srpnja čitateljima i slušateljima donijeti 12
            zanimljivih i raznolikih članaka koji će biti objavljivani u{" "}
            <b> online magazinu Brickzine </b> i čak četiri radijske emisije
            koje će biti dostupne <b> na mrežnim stranicama Radio Rože,</b> ali
            i na stranicama Vijeća mladih Benčiće te portalu{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://brickzine.hr/"
            >
              <b style={{ color: "red" }}> Brickzine.hr.</b>
            </a>
          </p>

          <div className="w3-center">
            <p>
              <b>
                <hr />
                PROJEKT KORONA KID JE PODRŽAN SA € 4.997,00 FINANCIJSKE PODRŠKE
                ISLANDA, LIHTENŠTAJNA I NORVEŠKE U OKVIRU EGP I NORVEŠKIH
                GRANTOVA.
              </b>{" "}
            </p>

            <img src="images/ACF-logo-s.png" alt="Korona Kid" />
            <hr />
            <img width="100%" src="images/kk_cover.png" alt="Korona Kid" />
          </div>
        </div>

        <div className="w3-container " id="team">
          <h2 className="w3-center w3-wide">PODCASTS: KORONA KID</h2> <hr />
          <div className="w3-row-padding">{this.ispisPodcasta()}</div>
          <hr />
        </div>

        <Footer />
      </div>
    );
  }
}

export default KoronaKid;
