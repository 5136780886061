import React, { Component } from "react";
import Navigacija from "./Navigacija";
import Footer from "./Footer";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";

class DigitalneRozice extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.state = {
      limit: 100,
      podacastPlay: "",
      podcasts_skolica: "",
      podcasts_nevidljive: "",
      podcasts_sos: "",

      emisije: [
        {
          name: "DIGITALNE ROŽICE",
          alias: "odasiljac",
          udruga: "",
          img: "",
          slogan: "",
          selected: true,
          content: `
          <h3><b>Informacije o projektu </b></h3><br>
          <p>Digitalne rožice europski je projekt koji u fokus postavlja opću 
          populaciju djece i mladih te djecu i mlade s teškoćama u razvoju. U suradnji s 
          partnerskim udrugama (RUKE, Muzikopter) koje već dugi niz godina provode 
          programske aktivnosti koje uključuju rad s djecom i mladima, nastoji se 
          utjecati na niz pozitivnih promjena: kulturnu i umjetničku edukaciju, 
          razvoj samostalnosti, poboljšanje slike o sebi te poboljšanje 
          socio-emocionalnog kontakta, izgradnju interpersonalnih odnosa, 
          osobni rast i razvoj kroz oslobađanje kreativnosti i individualnosti 
          djece i mladih.</p>

          <p>Uslijed novonastale situacije povezane s pandemijom COVID-19 dogodile su se promjene 
          u obliku prestanka ili reorganizacije svakidašnjih aktivnosti, što se očitovalo kroz 
          niz negativnih utjecaja na djecu i mlade:</p>

          <p> a) izdvajanje – segregacija iz kulturnog i društvenog života zajednice </p>
          <p> b) nedostupnost interaktivnih online programa neformalnih kulturnih i umjetničkih aktivnosti </p>
          <p> c) nedostupnost online programa muzikoterapije koji bi kreativnim nekonvencionalnim pristupom omogućili glazbenu edukaciju i rehabilitaciju djece s teškoćama u razvoju </p>
          <p> d) „gušenje” značajnih kreativnih, umjetničkih i socijalnih potencijala – ograničavanje ekspresije 
          vlastitih misli i afiniteta, zbog čega djeca i mladi, naročito oni s teškoćama u 
          razvoju posustaju u razvitku svojih potencijala, doživljava osjećaje poput 
          anksioznosti, brige, tjeskobe i straha</p>
          <p>e) škole su se u vrlo kratkom roku reorganizirale i omogućile pohađanje 
          nastave putem digitalnih platformi, ali djeca i mladi su izgubili svakodnevnu 
          strukturu i poticaj koji je školsko okruženje donosilo.</p>
          <p>Sve to potaknulo je nužnu intervenciju udruge Ri Rock i projektnih partnera da kroz sustav podrške djeci i mladima ponude načine za izražavanje osjećaja, želja i potreba kroz online kreativne, zabavne i stvaralačke aktivnosti.</p>
          <p>Svrha projekta „Digitalne rožice” jest provedbom participativnih online kulturnih i 
          umjetničkih aktivnosti razviti kreativne i socijalne vještine, doprinijeti 
          socijalnom uključivanju te poboljšati pristup kulturnim i umjetničkim sadržajima 
          djeci i mladima na području PGŽ-a. Aktivnosti projekta podrazumijevaju:</p>

          <p>a) online radionice audio digitalizacije kulturno-umjetničkih sadržaja 
          te montaže radijskih emisija za djecu i mlade, a koji će svojim angažmanom 
          ponuditi prilagođene i aktualne sadržaje svojim vršnjacima, uključujući 
          djecu s poteškoćama u čitanju te slijepu i slabovidnu djecu </p>
          <p>b) online radionice muzikoterapije za djecu i mlade s teškoćama u razvoju</p>
          <p>c) online emitiranje emisija s interaktivnim audiovizualnim sadržajima iz područja kulture i umjetnosti u suradnji s osnovnim školama.</p>
          
          </br>
          <h3><b>Ciljevi i očekivani rezultati projekta</b></h3>
          <p>Sudjelovanjem u aktivnostima audiodigitalizacije, djeca i mladi promiču 
          se u aktivne stvaratelje, kritičare i komentatore, potaknut će se razvoj 
          vještina čitanja s razumijevanjem te razvoj samopouzdanja i pozitivnih 
          osjećaja vezanih za čitanje.</p>
          <p>Stvaranje dostupnije i uključivije kulture.</p>
          <p>Razvijat će se novi oblici interdisciplinarnih medijskih sadržaja kojima će se 
          istraživati mogućnosti kreativnije i zabavnije upotrebe tradicionalnih medija, 
          ponuditi siguran sadržaj i povećati konzumacija kulturnih i umjetničkih sadržaja 
          među djecom i mladima.</p>
          <p>Online radionice muzikoterapije potaknuti će kreativnost, izražavanje, 
          razvoj i poboljšanje komunikacijskih sposobnosti, motoričkih funkcija te 
          kognitivnog i emocionalnog funkcioniranja djece i mladih s teškoćama u razvoju.</p>
          <p>Projektom „Digitalne rožice” razviti će se kreativne i socijalne vještine, 
          doprinijeti socijalnom uključivanju te poboljšati pristup kulturnim i 
          umjetničkim sadržajima djeci i mladima te djeci i mladima s poteškoćama 
          u razvoju na području PGŽ-a.</p> <br>


          <h3><b>Vrijednost</b></h3>

          <p>Ukupna vrijednost projekta iznosi 458.380,15 HRK (85% sredstava sufinancirano je iz 
            Europske unije iz Europskog socijalnog fonda, 15% sredstava osigurava Republika 
            Hrvatska iz Državnog proračuna)</p> <br>

            <h3><b>Partneri</b></h3>

            <ul style="color:#b42629">
	            <li><a href="https://vijecemladihbencic.com/" target="_blank">Udruga za Razvoj Umjetnosti, Kulture i Edukacije (Vijeće mladih Benčić)</a></li>
	            <li><a href="http://www.facebook.com/muzikopter/" target="_blank">Udruga za muzikoterapiju i edukacijsko rehabilitacijski rad Muzikopter</a></li>
	            <li><a href="https://gkr.hr/" target="_blank">Gradska knjižnica Rijeka</a></li>
            </ul> <br>

          <h3><b>Razdoblje provedbe projekta </b></h3>
          <p> 1. travnja 2021. do 1. travnja 2022.</p> <br>

          <h3><b>Kontakt osoba za više informacija </b></h3> 
          <p>Katerina Jovanović, voditeljica projekta <a style="color:#b42629" href="mailto:katerina.jovanoviic@gmail.com">katerina.jovanoviic@gmail.com</a>   </p><br>

          <h3><b>Za više informacija o EU projektima</b></h3> 

          <p class="eu-recenica"> 
            <a target="_blank" 
              rel="noopener noreferrer" 
              href="https://strukturnifondovi.hr/">
                www.strukturnifondovi.hr
            </a></br>
            <a target="_blank" 
              rel="noopener noreferrer" 
              href="http://www.esf.hr/operativni-program/">
                www.esf.hr
            </a></br>
          </p>

          <p><b>Sadržaj web stranice isključiva je odgovornost Udruge Ri Rock. </b><br>
          <span class="eu-recenica"><b>Projekt je sufinancirala Europska unija iz Europskog socijalnog fonda.</b></span></p>
   
          `,
        },
      ],
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.dohvacanjePodcasta();
    this.printNavigationEmisije();
  }

  executeScroll = () => this.myRef.current.scrollIntoView();

  dohvacanjePodcasta = async () => {
    try {
      const result_skolica = await fetch(
        "https://api.mixcloud.com/RadioRo%C5%BEa/playlists/skolica/cloudcasts/?limit=35"
      );
      const podcasts_skolica = await result_skolica.json();
      this.setState({ podcasts_skolica: podcasts_skolica.data.reverse() });
    } catch (error) {
      console.log(error);
    }

    try {
      const result_nevidljive = await fetch(
        "https://api.mixcloud.com/RadioRo%C5%BEa/playlists/nevidljive/cloudcasts/?limit=35"
      );
      const podcasts_nevidljive = await result_nevidljive.json();
      this.setState({
        podcasts_nevidljive: podcasts_nevidljive.data.reverse(),
      });
    } catch (error) {
      console.log(error);
    }

    try {
      const result_sos = await fetch(
        "https://api.mixcloud.com/RadioRo%C5%BEa/playlists/sos/cloudcasts/?limit=35"
      );
      const podcasts_sos = await result_sos.json();
      this.setState({ podcasts_sos: podcasts_sos.data.reverse() });
    } catch (error) {
      console.log(error);
    }
  };

  ispisPodcasta(alias) {
    if (this.state[`podcasts_${alias}`]) {
      return this.state[`podcasts_${alias}`].map((podcast) => (
        <div
          className="podcast__article"
          key={podcast.key}
          id={podcast.key}
          onClick={this.loadToPlay}
        >
          <figure className="story__shape">
            <img
              src={podcast.pictures.large}
              className="story__image"
              alt={podcast.name}
            />
            <figcaption className="story__caption">
              {podcast.name}
              <img
                id="play"
                className="play-pause-img"
                onClick={this.play}
                src="images/play-button.svg"
                alt="Radio Roža Play"
              />
            </figcaption>
          </figure>
        </div>
      ));
    } else {
      return (
        <div className="container">
          <p className="super-paragraph">
            Sadržaj u pripremi. Stiže uskoro! :D
          </p>
        </div>
      );
    }
  }

  loadToPlay = async (el) => {
    const podcastKey = el.target.closest(".podcast__article").id;
    console.log(el.target.parentNode.parentNode);

    if (!this.state.podacastPlay) {
      await this.setState({ podacastPlay: podcastKey });
    } else {
      let widget = window.Mixcloud.PlayerWidget(
        document.getElementById("my-widget-iframe")
      );
      await widget.ready;
      await widget.pause();
      await this.setState({ podacastPlay: podcastKey });
    }
  };

  printNavigationEmisije() {
    return this.state.emisije.map((emisija) => (
      <li
        className={`side-nav__item  ${
          emisija.selected ? "side-nav__item--active" : ""
        }`}
        id={emisija.alias}
        key={emisija.alias}
        onClick={this.onSelectedEmisija}
      >
        <a className="side-nav__link" href="#odasiljac">
          <span>{emisija.name}</span>
        </a>
      </li>
    ));
  }

  onSelectedEmisija = async (e) => {
    const alias = e.target.closest(".side-nav__item").id;
    await this.state.emisije.map((item) =>
      item.alias === alias ? (item.selected = true) : (item.selected = false)
    );
    this.executeScroll();
  };

  onSelectedIconEmisija = async (e) => {
    const alias = e.target.closest(".video-content__skolica").dataset.alias;
    const emisije = await this.state.emisije.map((item) => {
      return { ...item, selected: item.alias === alias ? true : false };
    });

    await this.setState({ emisije });
    this.executeScroll();
  };

  printContentSelectedEmisija() {
    const emisija = this.state.emisije.find((emisija) => emisija.selected);

    return (
      <div className="emisija">
        <h4 className="emisija__udruga">
          <strong>{emisija.udruga}</strong>
        </h4>
        {emisija.img ? (
          <div className="emisija__photobox">
            <img
              className="emisija__photo"
              src={`images/${emisija.img}`}
              alt={emisija.name}
            />
          </div>
        ) : (
          <></>
        )}

        {emisija.slogan ? (
          <figure className="quote">
            <blockquote>"{emisija.slogan}"</blockquote>
          </figure>
        ) : (
          <></>
        )}

        <div className="emisija__content">
          {ReactHtmlParser(emisija.content)}
        </div>
        <br />

        {!emisija.udruga ? (
          <img width="100%" src="images/logosi-dr.png" alt="Eu Logosi" />
        ) : (
          <></>
        )}

        {emisija.udruga ? (
          <>
            <h2 className="emisija__title">
              <strong>Podcasts</strong>
            </h2>
            <div className="podcast__box">
              {this.ispisPodcasta(emisija.alias)}
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
    );
  }

  render() {
    return (
      <div>
        <Helmet>
          <title>DIGITALNE ROŽICE - RADIO ROŽA</title>
        </Helmet>

        <Navigacija />

        <header className="odasiljac-header">
          <img
            className="dr-header__logo"
            src="images/logo_digrozice.svg"
            alt="Digitale Rožice - Radio Roža"
          />
          <img
            className="dr-header__logo"
            src="images/lenta-dr.png"
            alt="Digitale Rožice - Radio Roža"
          />
        </header>

        <main className="odasiljac" id="odasiljac">
          <nav className="odasiljac__sidebar">
            <ul className="side-nav">{this.printNavigationEmisije()}</ul>
          </nav>

          <div className="odasiljac__content" ref={this.myRef}>
            <div className="video-content">
              <img width="90%" src="images/art-dr.png" alt="Eu Logosi" />
            </div>
            <a href="https://www.rirock.hr/digitalne-rozice/">
              <button className="rr-botun-xl fbold w3-animate-right ">
                <img
                  className="logo-light svg-ikona"
                  src="images/podcasts.svg"
                  alt="radio-roza"
                />
                OTVORI ONLINE BROŠURU Digitalne Rožice »
              </button>
            </a>

            {this.printContentSelectedEmisija()}
          </div>
        </main>

        {this.state.podacastPlay === "" ? (
          <></>
        ) : (
          <div className="widget-container">
            <iframe
              id="my-widget-iframe"
              title="MixCloud Widget"
              width="100%"
              height="60"
              src={`https://www.mixcloud.com/widget/iframe/?hide_cover=1&mini=1&light=1&feed=${this.state.podacastPlay}`}
              frameBorder="0"
            ></iframe>
          </div>
        )}

        <Footer />
      </div>
    );
  }
}

export default DigitalneRozice;
