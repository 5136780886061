import React, { Component } from "react";

const PLAYING_DATA_URL = "https://stream.radio-roza.org/status-json.xsl";

class Plejer extends Component {
  constructor(props) {
    super(props);

    this.PLAYER_CONTAINER = React.createRef();

    this.state = {
      currentSong: "RADIO ROŽA LIVE",
      mainStream: "https://stream.radio-roza.org/live.mp3",
      sourceElement: document.getElementById("source1"),
      audioElement: document.querySelector("audio"),
    };
  }

  componentDidMount() {
    this.dohvacanjePjesme();
    setInterval(this.dohvacanjePjesme, 10000);
  }

  minimizePlayer = (e) => {
    console.log(e);
    const PLAYER_BOX = this.PLAYER_CONTAINER.current;
    PLAYER_BOX.classList.toggle("minimizer");
    document.querySelector(".player-song").classList.toggle("hide-song");
    e.target.src = e.target.src.includes("plus")
      ? "images/minus.svg"
      : "images/plus.svg";
  };

  pause = () => {
    const sourceElement = document.getElementById("source1");
    const audioElement = document.querySelector("audio");
    sourceElement.setAttribute("src", "");
    audioElement.pause();
    audioElement.load(); //This stop stream from loading in the background

    document.getElementById("play").style.display = "block";
    document.getElementById("pause").style.display = "none";
  };

  play = () => {
    const sourceElement = document.getElementById("source1");
    const audioElement = document.querySelector("audio");
    document.getElementById("play").style.display = "none";
    document.getElementById("pause").style.display = "block";

    if (!sourceElement.getAttribute("src")) {
      sourceElement.setAttribute("src", this.state.mainStream);
      audioElement.load(); // This restarts the stream download
    }
    audioElement.play();
  };

  dohvacanjePjesme = async () => {
    try {
      const response = await fetch(PLAYING_DATA_URL);
      const data = await response.json();
      const currentSong = data.icestats.source[3].title;
      if (currentSong) this.setState({ currentSong });
    } catch (err) {
      console.log(err);
    }
  };

  render() {
    return (
      <div ref={this.PLAYER_CONTAINER} className="player-container">
        <div className="player-content">
          <img
            onClick={this.minimizePlayer}
            className="player-mini-img"
            src="images/minus.svg"
            alt="Radio Roža Play"
          />

          <img
            id="play"
            className="play-pause-img"
            onClick={this.play}
            src="images/play-button.svg"
            alt="Radio Roža Play"
          />

          <img
            id="pause"
            className="play-pause-img"
            onClick={this.pause}
            style={{ display: "none" }}
            src="images/pause.svg"
            alt="Radio Roža Pause"
          />

          <p className="player-song">{this.state.currentSong}</p>

          <audio
            style={{ display: "none" }}
            id="player"
            preload="auto"
            controls
          >
            <source
              id="source1"
              src="https://stream.radio-roza.org/live.mp3"
              type="audio/mpeg"
            />
            <source
              src="https://stream.radio-roza.org/live.ogg"
              type="audio/mpeg"
            />
            Vaš internet preglednik ne podržava naš audio format.(ogg, mp3)
          </audio>
        </div>
      </div>
    );
  }
}

export default Plejer;
