import React, { Component } from 'react';
import Navigacija from './Navigacija';
import Footer from './Footer';
import {Helmet} from "react-helmet";

//Komponenta za kontakt
class Kontakt extends Component {

  //Kad se učita srolla se na vrh
  componentDidMount() {
     window.scrollTo(0, 0);
     this.menuActive();
  }

  menuActive(){
   document.getElementById("kontakt").classList.add('w3-red');
  }

  //Renderiranje sadržaja
  render() {
    return (
      <div>

         <Helmet>
             <title>RADIO ROŽA RIJEKA - KONTAKT</title>
         </Helmet>

        <Navigacija />


         {/* HEADER */}
         <header className=" header-ne-naslovnica  w3-display-container w3-center" id="home">

                <div className="header w3-text-white ">
                    <h1 className="w3-xxxlarge w3-wide w3-animate-opacity " >KONTAKT </h1>
                    <p className="w3-wide podnaslov-header">RADIO ROŽA - RADIO ZAJEDNICE</p><hr/>
                </div>
              
         </header>

         {/* KONTAKT */}
         <div className="w3-container main-padding naslovnica-ploca"  id="about">
          
              <div className="w3-center w3-large">
                <h3>E-mail:</h3>
                <p><b>Redakcija: </b>redakcija.radioroza@gmail.com</p>
                <p><b>Glazbeno uredništvo: </b>glazba.radioroza@gmail.com</p>
                <p><b>Općenito: </b>radio.rozari@gmail.com</p>
          
                <hr/>
                <h3>Kontakti:</h3>
                <p> Davor Popdankovski (Glavni urednik)  <br/>davor.poppy@gmail.com </p>
                <hr/>
                <p> Sebastijan Tomažin (Audio tehničar i tonac)  <br/>tomazin.sebastijan@gmail.com </p>
                <hr/>
                <p> Davor Margan (WEB & Informatička podrška)  <br/>davor@mar-com.hr</p>
              </div>
         </div>   
           
        
          <Footer />
      </div>
    );
  }
}

export default Kontakt;
