import React, { Component } from 'react';
import Navigacija from './Navigacija';
import Footer from './Footer';
import {Helmet} from "react-helmet";

//Komponenta za kontakt
class Kako extends Component {

  //Kad se učita srolla se na vrh
  componentDidMount() {
     window.scrollTo(0, 0);
  }

  //Renderiranje sadržaja
  render() {
    return (
      <div>

         <Helmet>
             <title>RADIO ROŽA RIJEKA - KAKO NAS MOŽETE SLUŠATI?</title>
         </Helmet>

        <Navigacija />


         {/* HEADER */}
         <header className=" header-ne-naslovnica  w3-display-container w3-center" id="home">

                <div className="header w3-text-white ">
                    <h1 className="w3-xxxlarge w3-wide w3-animate-opacity " >KAKO NAS MOŽETE SLUŠATI? </h1>
                    <p className="w3-wide podnaslov-header">RADIO ROŽA - RADIO ZAJEDNICE</p><hr/>
                </div>
              
         </header>

         {/* KONTAKT */}
         <div className="w3-container main-padding naslovnica-ploca"  id="about">
          
             <h3>1. Koristeći audio player na našim internet stranicama radio-roza.org</h3> <hr/>
             <img className="slika-kako" src="images/slika-player.png" alt="roza-plajer" /><hr/>
             
             <h3>2. Preuzimanjem m3u datoteke (na gumbu u navigaciji) koje možete otvoriti u svojem 
             audio playeru kao na primjer WinAmp ili Windows Media Player.</h3> <hr/>

             <a href="radio-roza.m3u">
              <button className="rr-botun fbold w3-animate-right"> 
              <img className="logo-light" width="35px" height="35px" src="images/winamp.png" alt="RR Download"/>RR DOWNLOAD
              </button></a>

              <a href="radio-roza.xspf">
              <button className="rr-botun fbold w3-animate-right"> 
              <img className="logo-light" width="35px" height="35px" src="images/vlc.png" alt="RR Download"/>RR DOWNLOAD
              </button></a><hr/>
             
               {/* GOOGLE PLAY BUTTON 
             <h3>3. Koristeći android aplikaciju Radio Roža na mobilnom uređaju.</h3><hr/>
             <img className="google_play" src="images/google_play.svg" alt="Radio Roža Google Play" /> <hr/>
             */} 

             <p> Audio i video prijenose uživo također možete pratiti na našim Facebook i Mixcloud stranicama. Linkovi se nalaze u footeru.</p>

         </div>   
           
        
          <Footer />
      </div>
    );
  }
}

export default Kako;
