import React, { Component } from "react";
import Navigacija from "./Navigacija";
import Footer from "./Footer";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

class Serijali extends Component {
  constructor(props) {
    super(props);
    this.state = {
      originalSerials: [],
      filteredSerials: [],
      searchTerm: "",
    };
  }

  menuActive = () => {
    document.getElementById("serijali").classList.add("w3-red");
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    this.getSerials();
    this.menuActive();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.searchTerm !== prevState.searchTerm) {
      this.searchSerials();
    }
  }

  getSerials = async () => {
    try {
      const result = await fetch(
        "https://mar-com.hr/radio-davor/rest/read_all_serijali.php"
      );
      const serials = await result.json();
      this.setState({
        originalSerials: serials.records,
        filteredSerials: serials.records,
      });
    } catch (error) {
      console.log(error);
    }
  };

  onFilterBySearch = (e) => {
    this.setState({ searchTerm: e.target.value });
  };

  searchSerials = () => {
    if (this.state.searchTerm === "") {
      this.setState({ filteredSerials: this.state.originalSerials });
    } else {
      const filters = this.state.originalSerials.filter((serial) =>
        serial.name.toUpperCase().includes(this.state.searchTerm.toUpperCase())
      );
      this.setState({ filteredSerials: filters });
    }
  };

  showSerijals() {
    if (this.state.filteredSerials.length > 0) {
      return this.state.filteredSerials.map((serial) => (
        <div className="w3-card" key={serial.id}>
          <Link to={"/serijal-" + serial.friendly_url}>
            <img
              className="h-slika-akti"
              src={
                "https://www.mar-com.hr/radio-davor/admin/serijal/uploads/" +
                serial.image
              }
              alt={serial.name}
            />
          </Link>
        </div>
      ));
    } else {
      return (
        <div className="container">
          <p>Nemamo serijala s tim imenom! :( </p>
        </div>
      );
    }
  }

  render() {
    return (
      <div>
        <Helmet>
          <title>RADIO ROŽA RIJEKA - SERIJALI</title>
        </Helmet>

        <Navigacija />

        <header
          className=" header-ne-naslovnica  w3-display-container w3-center"
          id="home"
        >
          <div className="header w3-text-white ">
            <h1 className="w3-xxxlarge w3-wide w3-animate-opacity text-upper">
              RR SERIJALI
            </h1>
            <p className="w3-wide podnaslov-header">RADIO ZAJEDNICE, RIJEKA</p>
            <hr />
          </div>
        </header>

        <main className="main-ctnr">
          <input
            className="w3-input w3-border"
            id="search"
            onChange={this.onFilterBySearch}
            type="text"
            placeholder="Pretraži serijale po naslovu..."
          />
          <hr />
          <p>
            <span role="img" aria-label="">
              ⚫
            </span>
            Serijali koji se više ne emitiraju.
          </p>
          <div className="grid-container">{this.showSerijals()}</div>
          <hr />
        </main>

        <Footer />
      </div>
    );
  }
}

export default Serijali;
