import React, { Component } from "react";
import Navigacija from "./Navigacija";
import Footer from "./Footer";
import { Helmet } from "react-helmet";

const crewMembers = [
  {
    name: "Davor Popdankovski",
    titula: "OSNIVAČ & GLAVNI UREDNIK",
    image: "dp.png",
    description: `El hefe. Voli ljuto.`,
    tags: "#sve",
    autor: "Vedran Karuza (Novi List)",
  },
  {
    name: "Sebastijan Tomažin",
    titula: "TEHNIČAR",
    image: "st.png",
    description: `Major zvuka Seba.`,
    tags: "#skoroSve",
    autor: "Tanja Kanazir",
  },
  {
    name: "Davor Margan",
    titula: "OSNIVAČ & IT PODRŠKA",
    image: "dm.png",
    description: `Biokill bugova na Roži.`,
    tags: "#informatika #dizajn",
    autor: "Tanja Kanazir",
  },

  {
    name: "David Dubrović",
    titula: "IT PODRŠKA & VIDEO",
    image: "dd.png",
    description: `IT Wizard.`,
    tags: "#informatika #zivouzivo",
  },

  {
    name: "Edin Vesković",
    titula: "IT PODRŠKA & TEHNIČAR",
    image: "edinveskovic.png",
    description: `Drag mu je mikrofon, ali draži su mu botuni na mikseru.`,
    tags: "#rijeckidir #rozaik #zivouzivo #hex",
    autor: "",
  },

  {
    name: "Martina Blečić",
    titula: "Urednica, voditeljica",
    image: "martinablecic.png",
    description: `Kažu da emisiju teških nota vodi kao Pola ure kulture.`,
    tags: "#bestijada #rozaik #hex",
    autor: "",
  },

  {
    name: "Ana Galant",
    titula: "Urednica, voditeljica",
    image: "anagalant.png",
    description: `Dežurna Istrijanka.`,
    tags: "#rijeckidir #rozaik",
    autor: "",
  },

  {
    name: "Marija Radja ",
    titula: "Urednica, voditeljica",
    image: "marijaradja.png",
    description: `Stručnjakinja za digresije i improvizaciju.`,
    tags: "#rijeckidir #rozaik #aircheck",
    autor: "",
  },

  {
    name: "Tomislav Milićević",
    titula: "Urednik, voditelj, selektor",
    image: "tomislavmilicevic.png",
    description: `Ako problema NEMA, to brzo mijenja Ras Padsystema. `,
    tags: "#worriesinthedance #bestijada #rozaik",
    autor: "Teo Kamov",
  },

  {
    name: "Lorena ",
    titula: "Urednica, selektorica",
    image: "lorenamiocic.png",
    description: `Žurka je kad basevi lupaju, a masa grize tapete.`,
    tags: "#worriesinthedance",
    autor: "",
  },

  {
    name: "Bruno Butorac",
    titula: "Urednik, voditelj, selektor",
    image: "bb.png",
    description: `Euripid - Dub partizan`,
    tags: "#worriesinthedance",
    autor: "Vedran Karuza (Novi List)",
  },

  {
    name: "Filip Žiljak ",
    titula: "Urednik, voditelj, selektor",
    image: "filipziljak.png",
    description: `GOAT`,
    tags: "#rapertoar",
    autor: "",
  },

  {
    name: "Marina Jakšić",
    titula: "Urednica, voditeljica, selektorica",
    image: "marinajaksic.png",
    description: `Ova zaljubljenica u slušanje i proučavanje glazbe voli čuti dobru traku više nego na žlicu pojest.`,
    tags: "#fader #rozaik #jabiram #zivouzivo",
    autor: "",
  },

  {
    name: "Ivan Dragnić - Gjuro",
    titula: "Urednik, voditelj, video",
    image: "ivandragnicgjuro.png",
    description: `Giovanni Tesoro - Dobar dečko s Hreljina.`,
    tags: "#fader #zivouzivo",
    autor: "",
  },

  {
    name: "Mia Hadžikadunić",
    titula: "Urednica i voditeljica",
    image: "miahadzikadunic.png",
    description: `Pušta, stvara, studira, svira, priča o njoj...muzici naravno! Sound girl u svakom smislu.`,
    tags: "#fader #jabiram",
    autor: "",
  },

  {
    name: "Neven Babin",
    titula: "Urednik i voditelj",
    image: "nevenbabin.png",
    description: `Stara škola.`,
    tags: "#pankeraj",
    autor: "",
  },

  {
    name: "Andrej Vučinić",
    titula: "Urednik i voditelj",
    image: "andrejvucinicskot.png",
    description: `Škot.`,
    tags: "#pankeraj",
    autor: "",
  },

  {
    name: "Hrvoje Šimić",
    titula: "Urednik i voditelj",
    image: "hrvojesimic.png",
    description: `Pank brčine.`,
    tags: "#pankeraj",
    autor: "",
  },

  {
    name: "Vanja Kauzlarić",
    titula: "Urednik i voditelj",
    image: "vanjakauzlariclico.png",
    description: `Ličo.`,
    tags: "#pankeraj",
    autor: "",
  },
];

//Komponenta za stranicu o nama
class Crew extends Component {
  //Kada se učita ide scroll na vrh stranice
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  //renderiranje sadržaja
  render() {
    return (
      <div>
        <Helmet>
          <title>RADIO ROŽA RIJEKA - O NAMA</title>
        </Helmet>
        <Navigacija />

        {/* HEADER */}
        <header
          className=" header-ne-naslovnica  w3-display-container w3-center"
          id="home"
        >
          <div className="header w3-text-white ">
            <h1 className="w3-xxxlarge w3-wide w3-animate-opacity ">RR CREW</h1>
            <p className="w3-wide podnaslov-header">RADIO ZAJEDNICE, RIJEKA</p>
            <hr />
          </div>
        </header>

        <div className="w3-container crew-container">
          {crewMembers.map((crewMember) => (
            <div className="crew-item w3-text-white crew-bg">
              <div className="crew-space">
                <div class=" w3-padding-16 border-radius-25">
                  <div className="w3-center">
                    <div className="crew-autor-slika">
                      {crewMember.autor ? (
                        ` FOTOGRAFIJA:${crewMember.autor}`
                      ) : (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: "&nbsp;",
                          }}
                        />
                      )}
                    </div>
                    <img
                      src={`images/${crewMember.image}`}
                      className="crew-slika"
                      alt="Davor Margan"
                      width="60%"
                    />
                    <div class="w3-container w3-center">
                      <div className="ime-crew"> {crewMember.name}</div>
                      <p className="titula ">{crewMember.titula}</p>
                      <p className="crew-description crew-tekst">
                        {crewMember.description ? (
                          crewMember.description
                        ) : (
                          <div
                            dangerouslySetInnerHTML={{
                              __html: "&nbsp;",
                            }}
                          />
                        )}
                      </p>
                      <p className="crew-tags ">
                        <hr />
                        {crewMember.tags}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>

        {/* O NAMA - SEKCIJA 1 */}
        <div className="w3-container najave-padding">
          <div className="w3-row-padding">
            <div className="w3-center">
              <p></p>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    );
  }
}

export default Crew;
